import _ from "lodash";
import * as actionTypes from "./types";

interface I_InitialState {
  workOrderList: any;
  allWorkordersList: any;
  allHFWorkordersList: any;
  propertyList: any;
  employeeList: any;
  taskList: any;
  assetList: any;
  contactList: any;
  contactInfo: any;
  workOrderDetail: any;
  employeeDetail: any;
}

const initialState: I_InitialState = {
  workOrderList: [],
  allWorkordersList: [],
  allHFWorkordersList: [],
  propertyList: [],
  employeeList: [],
  taskList: [],
  assetList: [],
  contactList: [],
  contactInfo: {},
  workOrderDetail: {},
  employeeDetail: {},
};

export const workOrderReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.GET_WORK_ORDER_LIST:
      return {
        ...state,
        workOrderList: action.payload,
      };
      case actionTypes.GET_ALL_WORK_ORDER_LIST:
      return {
        ...state,
        allWorkordersList: action.payload,
      };
      case actionTypes.DELETE_WORKORDER: {
        const clonedWorkOrders: any = _.cloneDeep(state.allWorkordersList);
        _.remove(clonedWorkOrders, {
          _id: action.payload._id,
        });
        return {
          ...state,
          locationsData: {
            ...state.allWorkordersList,
            workOrders: clonedWorkOrders,
          },
        };
      }
    case actionTypes.GET_PROPERTY_LIST:
      return {
        ...state,
        propertyList: action.payload,
      };
    case actionTypes.GET_EMPLOYEE_LIST:
      return {
        ...state,
        employeeList: action.payload,
      };
    case actionTypes.ASSETS_LIST:
      return {
        ...state,
        assetList: action.payload,
      };
    case actionTypes.GET_TASK_LIST:
      return {
        ...state,
        taskList: action.payload,
      };
    case actionTypes.GET_CONTACTS_LIST:
      return {
        ...state,
        contactList: action.payload,
      };
    case actionTypes.GET_CONTACT_INFO:
      return {
        ...state,
        contactInfo: action.payload,
      };
    case actionTypes.GET_SINGLE_WORK_ORDER:
      return {
        ...state,
        workOrderDetail: action.payload,
      };
    case actionTypes.GET_SINGLE_EMPLOYEE:
      return {
        ...state,
        employeeDetail: action.payload,
      };
      case actionTypes.GET_HF_WORK_ORDER_LIST:
      return {
        ...state,
        allHFWorkordersList: [...action.payload],
      };
    default:
      return state;
  }
};
