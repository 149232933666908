import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import _get from "lodash/get";
import _find from "lodash/find";
import { useNavigate } from "react-router-dom";
import {
  ACTION_generateEstimatePdf,
  ACTION_getEstimate,
  ACTION_sendEstimatePdf,
} from "../../../../store/estimate/actions";
import _ from "lodash";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import { getTotalEstimateAmount } from "../../../../utils/helpers";
import { ACTION_getWorkOrdersAPI } from "../../../../store/workorder/actions";
import { ACTION_getClients } from "../../../../store/client/actions";
import DeleteEstimateModal from "../../Estimates/delete/DeleteEstimateModal";
import PdfViewerModal from "../../Estimates/PdfViewerModal/PdfViewerModal";
import PDFActions from "../../Estimates/PDFActions";
import SendMailModal from "../../Estimates/sendEstimateMail/SendMailModal";
import { ACTION_getLocationDetail } from "../../../../store/location/actions";
import DeleteLocationModal from "../delete/DeleteLocationModal";

const LocationView: React.FC = () => {
  const dispatch: any = useDispatch();
  const navigate: any = useNavigate();

  const currentLocation = useSelector(
    (state: any) => state.location.locationDetail
  );

  const { user } = useSelector((state: any) => state.auth);
  const [contacts, setContacts] = useState([]);
  const [userDailyRateCostBreakdownIds, setUserDailyRateCostBreakdownIds] =
    useState<any>([]);

  const { clientsData } = useSelector((state: any) => state.client);
  const workOrderList = useSelector((state: any) => console.log(state));

  const [activeLocation, setActiveLocation] = useState<any>(null);
  const [showDeleteModal, setShowDeleteModal] = useState<any>(false);
  const [activePdfEstimate, setActivePdfEstimate] = useState<any>(null);
  const [showPdf, setShowPdf] = useState(false);
  const [showSendEstimateModal, setShowSendEstimateModal] =
    useState<any>(false);
  const [downloadPdfId, setDownloadPdfId] = useState<any>(null);
  const [showDownloadPdf, setShowDownloadPdf] = useState(false);
  const [workList, setWorkList] = useState<any>([]);
  const [locationId, setLocationId] = useState<string>();
  const [currentClient, setCurrentClient] = useState<any>({});

  const clientList = useSelector(
    (state: any) => state.client.clientsData.clients
  );

  useEffect(() => {
    const id = window.location.pathname.split("/")[3];
    setLocationId(id);
    dispatch(ACTION_getLocationDetail(id));
  }, []);

  useEffect(() => {
    const client = clientList?.find((item: any) => {
      if (item?.locations?.some((ci) => ci._id === locationId)) {
        return item;
      }
    });
    if (client) {
      setCurrentClient(client);
    }
  }, [clientList]);

  console.log({ currentLocation });
  // console.log(client)

  let formik = useFormik({
    initialValues: {
      name: currentLocation?.locationName ? currentLocation.locationName : "",
      address: currentLocation?.street ? currentLocation.street : "",
      city: currentLocation?.city ? currentLocation.city : "",
      state: currentLocation?.state ? currentLocation.state : "",
      clientName: currentLocation?.clientId?.DisplayAs ? currentLocation?.clientId?.DisplayAs : "",
      status: currentLocation?.status ? currentLocation?.status : "ACTIVE"
    },
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {},
  });

  // console.log("In VIEW",workList[0]?.Data?.Number);

  // useEffect(() => {
  //   if (userDailyCostBreakDownList && userDailyCostBreakDownList.length) {
  //     const _userDailyRateCostBreakdownIds: any[] = [];
  //     userDailyCostBreakDownList.forEach((element: any) => {
  //       _userDailyRateCostBreakdownIds.push(element._id);
  //     });
  //     setUserDailyRateCostBreakdownIds(_userDailyRateCostBreakdownIds);
  //   } else {
  //     setUserDailyRateCostBreakdownIds([]);
  //   }
  // }, [userDailyCostBreakDownList]);

  // useEffect(() => {
  //   if (formik.values.client) {
  //     let filterClient = _find(_get(clientsData, "clients", []), {
  //       _id: formik.values.client
  //     });
  //     if (filterClient && filterClient?.contacts) {
  //       setContacts(filterClient?.contacts);
  //     } else {
  //       setContacts([]);
  //     }
  //   }
  // }, [formik.values.client]);

  const showPlainDescription = (description) => {
    const withoutHtmlTagsDescription = description.replace(/<[^>]+>/g, "");
    return withoutHtmlTagsDescription.replaceAll("&nbsp;", "");
  };

  const showDeleteModalHandler = (_location: any) => {
    setActiveLocation(_location);
    setShowDeleteModal(true);
  };

  const closeDeleteModalHandler = () => {
    setActiveLocation(null);
    setShowDeleteModal(false);
  };

  const onDownloadBtnHandler = (_id: string) => {
    dispatch(ACTION_generateEstimatePdf(_id));
  };
  const showPdfHandler = (_estimate: any) => {
    console.log("checking _estimate: ", _estimate);
    setActivePdfEstimate(_estimate);
    setShowPdf(true);
  };

  const closePdfHandler = () => {
    setActivePdfEstimate(null);
    setShowPdf(false);
  };

  const showSendEstimateModalHandler = (_estimate: any) => {
    setShowSendEstimateModal(true);
    setActiveLocation(_estimate);
  };

  const closeSendEstimateModalHandler = () => {
    setShowSendEstimateModal(false);
  };

  const closeDownloadPdfHandler = () => {
    setDownloadPdfId(null);
    setShowDownloadPdf(false);
  };

  const onSendEstimateBtnHandler = (activeLocation: any, reqPacket: any) => {
    closeDownloadPdfHandler();
    dispatch(ACTION_sendEstimatePdf(activeLocation, reqPacket));
  };

  return (
    <>
      {showDeleteModal && (
        <DeleteLocationModal
          closeDeleteModalHandler={closeDeleteModalHandler}
          locationData={activeLocation}
        />
      )}
      {showPdf && (
        <PdfViewerModal
          closePdfHandler={closePdfHandler}
          clientsData={currentLocation?.clinetId._id}
        />
      )}
      {showSendEstimateModal && (
        <SendMailModal
          closeSendEstimateModalHandler={closeSendEstimateModalHandler}
          clientsData={activeLocation._id}
          onSendEstimateBtnHandler={onSendEstimateBtnHandler}
        />
      )}
      {/* <div className="fv-row mb-7 d-flex justify-content-between align-items-center">
				<label className="fw-bold fs-6 mb-2">PDF Actions</label>
				<div>
					<PDFActions
						clientsData={clinetById}
						showPdfHandler={showPdfHandler}
						showSendEstimateModalHandler={() =>
							showSendEstimateModalHandler(clinetById)
						}
						onDownloadBtnHandler={() => onDownloadBtnHandler(clientId!)}
						onSendEstimateBtnHandler={onSendEstimateBtnHandler}
					/>
				</div>
			</div> */}
      <form className="form">
        <div className="fv-row mb-7">
          <Row>
            <Col>
              <div className="fv-row mb-7">
                <label className="fw-bold fs-6 mb-2">Name</label>
                <p>{formik.values?.name}</p>
              </div>

              <div className="fv-row mb-7">
                <label className="fw-bold fs-6 mb-2">Address</label>
                <p>{formik.values?.address}</p>
              </div>
            </Col>

            <Col>
              <div className="fv-row mb-7">
                <label className="fw-bold fs-6 mb-2">City</label>
                <p>{formik.values?.city}</p>
              </div>
              <div className="fv-row mb-7">
                <label className="fw-bold fs-6 mb-2">Client</label>
                <p>{formik.values?.clientName}</p>
              </div>
            </Col>

            <div className="fv-row mb-7">
              <label className="fw-bold fs-6 mb-2">State</label>
              <p>{formik.values?.state}</p>
            </div>

            <div className="fv-row mb-7">
              <label className="fw-bold fs-6 mb-2">Status</label>
              <p>{formik.values?.status}</p>
            </div>
          </Row>

          <Row>
            <Col xs={6} md={6} lg={6}>
              <div className="text-end pt-15">
                <button
                  type="button"
                  className="btn btn-danger"
                  style={{
                    width: "100%",
                  }}
                  onClick={() => showDeleteModalHandler(currentLocation?._id)}
                >
                  Delete
                </button>
              </div>
            </Col>
            <Col xs={6} md={6} lg={6}>
              <div className="text-end pt-15">
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{
                    width: "100%",
                  }}
                  onClick={() =>
                    navigate(`/location/update/${currentLocation?._id}`)
                  }
                >
                  Edit
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </form>
    </>
  );
};

export default LocationView;
