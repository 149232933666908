import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { number } from "yup";
import { ACTION_deleteEstimate } from "../../../../store/estimate/actions";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { getMailBodyHTML } from "../../../../utils/helpers";

const SendMailModal = (props: any) => {
  const [email, setEmail] = useState<any>();
  const [subject, setSubject] = useState<any>();
  const [body, setBody] = useState<any>();

  const { estimateData } = props;

  const dispatch: any = useDispatch();
  const navigate: any = useNavigate();

  useEffect(() => {
    document.body.classList.add("modal-open");
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  useEffect(() => {
    console.log(estimateData);
    setEmail(estimateData.client.emailAddress);
    setSubject(`QUOTE-${estimateData.referenceNumber}`);
    setBody(getMailBodyHTML(estimateData));
  }, [estimateData]);

  //   console.log({ props });

  const handle = () => {
    props.onSendEstimateBtnHandler(props.estimateData._id, {
      email,
      emailBody: body,
      emailSubject: subject,
    });
  };

  const getDocName = () => {
    const format2digit = (number) => {
      return number > 9 ? number : "0" + number;
    };

    const formatRefName = (ref: string) => {
      return ref.substring(0, 175);
    };
    console.log({ estimateData });
    let date: any = new Date(estimateData.date);
    date = `${date.getFullYear()}-${format2digit(
      date.getMonth() + 1
    )}-${format2digit(date.getDate() + 1)}`;
    return `${formatRefName(estimateData.referenceNumber)}_${date}.PDF`;
  };

  const onBodyChanged = (event: any, editor: any) => {
    // console.log(editor.getData());
  };

  const toogleCheckBox = () => {
    const getEmailValue = (mail: string) => {
      return mail.includes(",")
        ? mail.substring(0, mail.indexOf(","))
        : mail + ", " + estimateData.contact.emailAddress;
    };

    setEmail((prev) => getEmailValue(prev));
  };

  return (
    <>
      <div
        className="modal fade show d-block"
        id="kt_modal_add_user"
        role="dialog"
        tabIndex={-1}
        aria-modal="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          style={{ width: "950px !important" }}
        >
          <div className="modal-content">
            <div
              className="modal-body scroll-y mx-5 mx-xl-15 my-7"
              style={{ textAlign: "center" }}
            >
              <div className="fv-row mb-7 ">
                <label className="required fw-bold fs-6 mb-2  d-flex justify-content-start">
                  Email
                </label>

                <input
                  type="text"
                  placeholder="Email"
                  name="email"
                  className={"form-control form-control-solid mb-3 mb-lg-0"}
                  autoComplete="off"
                  defaultValue={email}
                  onChange={(e) => setEmail(e.target.value)}
                />

                <div className="d-flex justify-content-end">
                  <label style={{ marginRight: "3px" }}>
                    {estimateData.contact?.emailAddress}
                  </label>
                  <input type="checkbox" onClick={toogleCheckBox}></input>
                </div>
              </div>
              <div className="fv-row mb-7">
                <label className="required fw-bold fs-6 mb-2 d-flex justify-content-start">
                  Subject
                </label>
                <input
                  type="text"
                  placeholder="Email Subject"
                  name="subject"
                  className={"form-control form-control-solid mb-3 mb-lg-0"}
                  autoComplete="off"
                  defaultValue={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </div>
              <div className="fv-row mb-7">
                <label className="required fw-bold fs-6 mb-2  d-flex justify-content-start">
                  Body
                </label>
                <CKEditor
                  id="inputText"
                  type="inline"
                  data={body}
                  className={""}
                  editor={ClassicEditor}
                  onChange={onBodyChanged}
                />
                {/* <textarea
                  placeholder="Email Body"
                  name="body"
                  rows={6}
                  className={'form-control form-control-solid mb-3 mb-lg-0'}
                  autoComplete="off"
                  required
                  value={body}
                  onChange={e => setBody(e.target.value)}
                ></textarea> */}
              </div>
              <div className="fv-row mb-7">
                <input
                  type="text"
                  placeholder="Pdf"
                  name="pdf"
                  className={"form-control form-control-solid mb-3 mb-lg-0"}
                  autoComplete="off"
                  defaultValue={getDocName()}
                  disabled
                />
              </div>
              <div style={{ marginTop: "15px" }}>
                <div className="d flex">
                  <Row>
                    <Col>
                      <div className="text-end pt-5">
                        <button
                          className="btn btn-primary"
                          style={{
                            width: "100%",
                          }}
                          onClick={() => handle()}
                        >
                          Save
                        </button>
                      </div>
                    </Col>
                    <Col xs={6} md={6} lg={6}>
                      <div className="text-end pt-5">
                        <button
                          style={{
                            width: "100%",
                          }}
                          type="submit"
                          className="btn btn-secondary"
                          onClick={() => props.closeSendEstimateModalHandler()}
                        >
                          Cancel
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  );
};

export default SendMailModal;
