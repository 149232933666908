import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import _get from "lodash/get";
import moment from "moment";
import _find from "lodash/find";
import { useNavigate } from "react-router-dom";
// import { ACTION_postWorkOrder } from "../../../../store/workOrder/actions"
import { Col, Row } from "react-bootstrap";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import CustomDropdown from "../../../../components/CustomDropdown/CustomDropdown";
import { ACTION_getHFWorkOrderByIdAPI, ACTION_updateWorkOrder } from "../../../../store/workorder/actions";
import { flagDropdownData, PriorityDropdownData, statusDropdownData } from "../create/WorkOrderForm";
import { ACTION_getClients } from "../../../../store/client/actions";
import { ACTION_getUsers } from "../../../../store/users/actions";

const editWorkOrdersSchema = Yup.object().shape({
  // status: Yup.string().required("Status is required"),
  // wo: Yup.string().required("WO# is required"),
  // po: Yup.string(),
  // space: Yup.string().required("Space is required"),
  // scheduleTo: Yup.date().required("Date is required"),
  // priority: Yup.string().required("Priority is required"),
  // flag: Yup.string().required("Flag is required"),
  // description: Yup.string().required("Description is required"),
  // assignTo: Yup.string().required("Assign Employee name is required"),
});

const EditWorkOrderForm: React.FC = () => {
  const dispatch: any = useDispatch();
  const navigate: any = useNavigate();

  const { user } = useSelector((state: any) => state.auth);
  const [currentClient, setCurrentClient] = useState<any>({})
  const usersList = useSelector((state: any) => state.users.usersList);
  const currentWorkOrder: any = useSelector(
    (state: any) => state.workOrder.workOrderDetail
  );
  const clientsList = useSelector(
    (state: any) => state.client.clientsData.clients
  );

useEffect(()=>{
  const id = window.location.pathname.split("/")[3];
  dispatch(ACTION_getHFWorkOrderByIdAPI(id));
  dispatch(ACTION_getClients());
  dispatch(ACTION_getUsers());
}, [])


useEffect(()=>{
  const client = clientsList?.find(client => client._id === currentWorkOrder?.Customer?._id);
  setCurrentClient(client)
},[currentWorkOrder])

console.log({currentWorkOrder})
console.log({clientsList})
console.log({currentClient})

const start = moment.tz(currentWorkOrder?.ScheduledStartUtc, "UTC");

console.log(start.tz("MST").format("YYYY-MM-DDTHH:mm:ss"))

  const formik = useFormik({
    initialValues: {
      status: currentWorkOrder?.StatusId,
      wo: currentWorkOrder?.Number,
      po: currentWorkOrder?.PoNumber,
      space: currentWorkOrder?.ShortLocation,
      scheduleTo: currentWorkOrder?.ScheduledStartUtc
      ? start?.tz("UTC").format("YYYY-MM-DDTHH:mm:ss")
      : "",
      priority: currentWorkOrder?.Priority,
      flag: currentWorkOrder?.Flag,
      description: currentWorkOrder?.TaskRefinement,
      ContactName: currentWorkOrder?.Contact ? currentWorkOrder?.Contact : currentWorkOrder?.ContactName ,
      assignTo: currentWorkOrder?.Employee,
      client: currentWorkOrder?.Customer
    },
    validationSchema: editWorkOrdersSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      handleSubmitWorkOrderForm();
    },
  });

  const handleSubmitWorkOrderForm = async () => {
    let reqData = {
      _id: currentWorkOrder?._id,
      userId: user._id,
      StatusId: formik.values.status,
      Number: formik.values.wo,
      PoNumber: formik.values.po,
      ShortLocation: formik.values.space,
      ScheduledStartUtc: formik.values.scheduleTo,
      Priority: formik.values.priority,
      Flag: formik.values.flag,
      Customer: formik.values.client,
      TaskRefinement: formik.values.description,
      Contact: formik.values.ContactName._id,
      Employee: formik.values.assignTo,
    };

    console.log(reqData);
    dispatch(ACTION_updateWorkOrder(reqData, navigate))
    // dispatch(ACTION_postWorkOrder(reqData, navigate))
  };

  const inputHandler = (event: any, editor: any) => {
    // formik.setFieldValue(
    //   "description",
    //   editor.getData().replace(/<[^>]*>/g, "")
    // );

    formik.setFieldValue("description", editor.getData());

    console.info("----------------------------");
    console.info("data =>", editor.getData().replace(/<[^>]*>/g, ""));
    console.info("data =>", editor.getData());
    console.info("----------------------------");
  };

  console.log({usersList})
  console.log("Contact To : ",formik.getFieldProps("ContactName"))


  return (
    <form className="form" onSubmit={formik.handleSubmit}>
      <div className="fv-row mb-7">
        <Row>
          <Col>
          <div className="fv-row mb-7">
              <label className="required fw-bold fs-6 mb-2">Client</label>
              <CustomDropdown
                {...formik.getFieldProps("client")}
                name="client"
                className=""
                value={
                  {
                    label: currentWorkOrder?.Customer?.DisplayAs,
                    value: currentWorkOrder?.Customer?._id
                  }
                }
                options={clientsList?.map((item: any) => {
                  return {
                    label: item?.DisplayAs,
                    value: item?._id,
                  };
                })}
                onChange={(e) => {
                  const client = clientsList.find(client => client._id === e.value)
                  setCurrentClient(client)
                  formik.setFieldValue("client", e.value);
                }}
              />
            </div>
            <div className="fv-row mb-7">
              <label className="required fw-bold fs-6 mb-2">Status</label>
              <CustomDropdown
                {...formik.getFieldProps("status")}
                name="status"
                className=""
                value={
                  {
                    label: currentWorkOrder?.StatusId,
                    value: currentWorkOrder?.StatusId
                  }
                }
                options={(statusDropdownData as any).map((item: any) => {
                  return {
                    label: item?.label,
                    value: item?.value,
                  };
                })}
                onChange={(e) => {
                  formik.setFieldValue("status", e.value);
                }}
              />
            </div>
            <div className="fv-row mb-7">
              <label className="required fw-bold fs-6 mb-2">WO#</label>
              <input
                {...formik.getFieldProps("wo")}
                type="text"
                placeholder="Enter wo number"
                name="wo"
                className={clsx(
                  "form-control form-control-solid mb-3 mb-lg-0",
                  {
                    "is-invalid": formik.touched.wo && formik.errors.wo,
                  },
                  {
                    "is-valid": formik.touched.wo && !formik.errors.wo,
                  }
                )}
                autoComplete="off"
              />
            </div>
            <div className="fv-row mb-7">
              <label className="required fw-bold fs-6 mb-2">PO#</label>
              <input
                {...formik.getFieldProps("po")}
                type="text"
                placeholder="Enter PO number"
                name="po"
                className={clsx(
                  "form-control form-control-solid mb-3 mb-lg-0",
                  {
                    "is-invalid": formik.touched.po && formik.errors.po,
                  },
                  {
                    "is-valid": formik.touched.po && !formik.errors.po,
                  }
                )}
                autoComplete="off"
              />
            </div>
            <div className="fv-row mb-7">
              <label className="required fw-bold fs-6 mb-2">Location</label>
              <CustomDropdown
                {...formik.getFieldProps("space")}
                name="space"
                value={
                  {
                    label : currentWorkOrder?.ShortLocation,
                  }
                }
                className=""
                options={currentClient?.Spaces?.map((item: any) => {
                  return {
                    value: item._id,
                    label: item?.locationName,
                  };
                })}
                onChange={(e) => {
                  formik.setFieldValue("space", e.value);
                }}
              />
            </div>
            <div className="fv-row mb-7">
              <label className="required fw-bold fs-6 mb-2">Schedule To</label>
              <input
                  {...formik.getFieldProps("scheduleTo")}
                  type="datetime-local"
                  name="scheduleTo"
                  defaultValue={formik.values.scheduleTo}
                  className={clsx(
                    "form-control form-control-solid mb-3 mb-lg-0",
                    { "is-invalid": formik.touched.scheduleTo && formik.errors.scheduleTo },
                    {
                      "is-valid": formik.touched.scheduleTo && !formik.errors.scheduleTo,
                    }
                  )}
                  autoComplete="off"
                />
              {/* <input
                {...formik.getFieldProps("scheduleTo")}
                type="date"
                // value={new Date(currentWorkOrder?.scheduleTo)}
                name="scheduleTo"
                className={clsx(
                  "form-control form-control-solid mb-3 mb-lg-0",
                  {
                    "is-invalid":
                      formik.touched.scheduleTo && formik.errors.scheduleTo,
                  },
                  {
                    "is-valid":
                      formik.touched.scheduleTo && !formik.errors.scheduleTo,
                  }
                )}
                autoComplete="off"
              /> */}
            </div>
            <div className="fv-row mb-7">
              <label className="fw-bold fs-6 mb-2">Assign To</label>
              <CustomDropdown
                {...formik.getFieldProps("Employee")}
                name="Employee"
                value={{label : currentWorkOrder?.Employee?.FirstName}}
                className=""
                options={usersList?.map((item: any) => {
                  return {
                    label: item?.FirstName,
                    value: item._id,
                  };
                })}
                onChange={(e) => {
                  formik.setFieldValue("Employee", e.value);
                }}
              />
              
            </div>
          </Col>

          <Col>
            <div className="fv-row mb-7">
              <label className="required fw-bold fs-6 mb-2">Priority</label>
              <CustomDropdown
                {...formik.getFieldProps("priority")}
                name="priority"
                className=""
                value={currentWorkOrder?.Priority}
                options={(PriorityDropdownData as any).map((item: any) => {
                  return {
                    data: item,
                    label: item?.label,
                    value: item?.Id,
                  };
                })}
                onChange={(e) => {
                  formik.setFieldValue("priority", e.data);
                }}
              />
            </div>
            <div className="fv-row mb-7">
              <label className="required fw-bold fs-6 mb-2">Flag</label>
              <CustomDropdown
                {...formik.getFieldProps("flag")}
                name="flag"
                className=""
                value={{
                  label: currentWorkOrder?.Flag?.DisplayAs,
                  value: currentWorkOrder?.Flag?.value
                }}
                options={(flagDropdownData as any).map((item: any) => {
                  return {
                    data: item,
                    label: item?.DisplayAs,
                    value: item?.value,
                  };
                })}
                onChange={(e) => {
                  formik.setFieldValue("flag", e.data);
                }}
              />
            </div>
            <div className="fv-row mb-7">
              <label className="required fw-bold fs-6 mb-2">Task</label>
              {/* <textarea
                {...formik.getFieldProps("description")}
                placeholder="Terms"
                name="description"
                rows={3}
                className={clsx(
                  "form-control form-control-solid mb-3 mb-lg-0",
                  {
                    "is-invalid":
                      formik.touched.description && formik.errors.description,
                  },
                  {
                    "is-valid":
                      formik.touched.description && !formik.errors.description,
                  }
                )}
                autoComplete="off"
              ></textarea> */}
              <CKEditor
                id="inputText"
                type="inline"
                data={formik.values.description}
                className={clsx(
                  "form-control form-control-solid mb-3 mb-lg-0",
                  {
                    "is-invalid":
                      formik.touched.description && formik.errors.description,
                  },
                  {
                    "is-valid":
                      formik.touched.description && !formik.errors.description,
                  }
                )}
                editor={ClassicEditor}
                onChange={inputHandler}
              />

            </div>
            <div className="fv-row mb-7">
              <label className="fw-bold fs-6 mb-2">Contact Name</label>
              <CustomDropdown
                {...formik.getFieldProps("ContactName")}
                name="ContactName"
                value={
                  {
                    label: currentWorkOrder?.Contact?.fullName ? currentWorkOrder?.Contact?.fullName : currentWorkOrder?.ContactName,
                  }
                }
                className=""
                options={currentClient?.Contacts?.map((item: any) => {
                  return {
                    data: item,
                    label: item?.fullName,
                    value: item?._id,
                  };
                })}
                onChange={(e) => {
                  formik.setFieldValue("ContactName", e.data);
                }}
              />
            </div>
          </Col>
        </Row>

        <div className="text-end pt-15">
          <button
            type="reset"
            className="btn btn-light me-3"
            data-kt-users-modal-action="cancel"
            onClick={() => {
              navigate("/hf-order/list");
            }}
          >
            Discard
          </button>

          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </div>
      </div>
    </form>
  );
};

export default EditWorkOrderForm;
