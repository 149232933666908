import React from "react";
import moment from "moment";

const BookMarkItem = ({ workOrderData, getWorkOrder }) => {
  let data = workOrderData?.Data;
  return (
    <>
      <div className="alert alert-secondary border-0 d-flex align-items-center p-5 mb-5" onClick={() => getWorkOrder(data?.Number)} style={{cursor: "pointer"}}>
        <div className="d-flex flex-column">
          <span className="text-black">{moment(data?.DtScheduledStart).format("MMM DD, YYYY hh:mm A") as any}</span>
          {/* <span className="text-black"><a target="_blank" href={`https://enterprise.corrigo.com/corpnet/workorder/workorderdetails.aspx/${data?.Id}`}>{data?.Number}</a></span> */}
          <span className="text-black"><a target="_blank" href={`https://am-ce99a.corrigo.com/corpnet/workorder/workorderdetails.aspx/${data?.Id}`} rel="noreferrer">{data?.Number}</a></span>
          <h5 className="mb-1">{data?.TaskRefinement}</h5>
        </div>
      </div>
    </>
  );
};

export default BookMarkItem;
