import React, { useEffect, useState } from "react";
import { PageTitle } from "../../../_metronic/layout/core";
import { useIntl } from "react-intl";
import BookMark from "./BookMark/BookMark";
import CalendarItem from "./CalendarItem";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import WorkOrderDetail from "./BookMark/WorkOrderDetail";
import { ACTION_getWorkOrders } from "../../../store/workorder/actions";

const Calendar: React.FC = () => {
  const dispatch: any = useDispatch();
  const intl = useIntl();
  const [date, setDate] = useState(moment());
  const { workOrderList } = useSelector((state: any) => state.workOrder);

  const [filterBookMarks, setFilterBookMarks] = useState<any[]>([]);
  const [currentWorkOrder, setCurrentWorkOrder] = useState<any>({});

  useEffect(() => {
    dispatch(ACTION_getWorkOrders());
  }, []);

  useEffect(() => {
    if (workOrderList?.length) {
      let newList = workOrderList?.filter((item) => {
        if (item?.Data?.ScheduledStartUtc) {
          let compareDate = moment(item?.Data?.ScheduledStartUtc);
          return compareDate.isSame(date, "month");
        } else {
          return false;
        }
      });

      //   console.log(newList[0].Data.DtCreated);
      const updatedNewList = newList?.sort(
        (a: any, b: any) =>
          new Date(
            new Date(b.Data?.DtScheduledStart).getTime() -
              new Date(a.Data?.DtScheduledStart).getTime()
          )
      );
      setFilterBookMarks(updatedNewList);
      setCurrentWorkOrder({});
      console.info("----------------------------");
      console.info("updatedNewList =>", updatedNewList);
      console.info("----------------------------");
    }
  }, [workOrderList, date]);

  const getWorkOrder = (workOrderNumber: string) => {
    console.log("WORK Number : ", workOrderNumber);
    const workOrder = workOrderList.find(
      (wo) => wo.Data.Number === workOrderNumber
    );
    console.log({ workOrder });
    setCurrentWorkOrder(workOrder);
  };

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU.CALENDAR" })}
      </PageTitle>
      <div>
        <div className="row gy-5 g-xl-8">
          <div className="col-xxl-6">
            <CalendarItem
              setDate={setDate}
              getWorkOrder={getWorkOrder}
              workOrderList={workOrderList}
            />
          </div>
          <div className="col-xxl-6">
            {currentWorkOrder?.Data?.Number ? (
              <WorkOrderDetail workOrderData={currentWorkOrder} />
            ) : (
              filterBookMarks.length > 0 && (
                <BookMark
                  date={date}
                  workOrderList={filterBookMarks}
                  getWorkOrder={getWorkOrder}
                />
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Calendar;
