import React, { useState } from "react";
import OrderReviewConfirm from "./OrderReviewConfirm";
import WorkOrderCreateForm from "./WorkOrderCreateForm";
import WorkOrderForm from "./WorkOrderForm";

const WorkOrderCreate: React.FC = () => {
  const [next, setNext] = useState<any>();

  return (
    <div className="row gy-5 g-xl-8">
      <div className="col-xxl-6">
        <div className="card card-xl-stretch mb-xl-8">
          <div className="card-body pt-5">
            <WorkOrderForm />
            {/* {!next ? (
							<WorkOrderCreateForm setNext={setNext} />
						) : (
							<OrderReviewConfirm setNext={setNext} />
						)} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkOrderCreate;
