import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ACTION_getClients } from "../../../../store/client/actions";
import { ACTION_getContacts } from "../../../../store/contact/actions";
import { ACTION_getUsers } from "../../../../store/users/actions";
import DeleteContactModal from "../delete/DeleteUserModal";
import DeleteLocationModal from "../delete/DeleteUserModal";
import UserTable from "./table/UserTable";
// import DeleteLocationModal from "../delete/DeleteLocationModal"

export const UserList: React.FC = () => {
  const dispatch: any = useDispatch();
  const usersList = useSelector((state: any) => state.users.usersList);
  const usersList2 = useSelector((state: any) => console.log(state));

  const [showDeleteModal, setShowDeleteModal] = useState<any>(false);
  const [activeLocation, setActiveLocation] = useState<any>(null);

  useEffect(() => {
    dispatch(ACTION_getUsers());
  }, []);

  const showDeleteModalHandler = (_location: any) => {
    setActiveLocation(_location);
    setShowDeleteModal(true);
  };

  const closeDeleteModalHandler = () => {
    setActiveLocation(null);
    setShowDeleteModal(false);
  };

  console.log("main user list : ", { usersList });
  // useEffect(() => {
  //   setContactList(clientContactList);
  // }, [clientContactList]);
  // useEffect(() => {
  //   setContactList(_.get(locationsData, "locations", []));
  // }, [locationsData?.locations]);

  return (
    <div>
      {showDeleteModal && (
        <DeleteContactModal
          closeDeleteModalHandler={closeDeleteModalHandler}
          locationData={activeLocation}
        />
      )}
      {usersList.length > 0 && (
        <UserTable
          usersList={usersList}
          showDeleteModalHandler={showDeleteModalHandler}
        />
      )}
    </div>
  );
};
