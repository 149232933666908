import clsx from "clsx";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { useLayout } from "../../../core/LayoutProvider";
import { usePageData } from "../../../core/PageData";
import { useSelector } from "react-redux";

const DefaultTitle: FC = () => {
  const { pageTitle, pageDescription, pageBreadcrumbs } = usePageData();
  const { config, classes } = useLayout();
  const userRole = useSelector((state: any) => state.auth.user.role);
  return (
    <div>
      <div
        id="kt_page_title"
        data-kt-swapper="true"
        data-kt-swapper-mode="prepend"
        data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
        className={clsx("page-title", classes.pageTitle.join(" "))}
        style={{ display: "flex", justifyContent: "start" }}
      >
        {/* begin::Title */}
        {pageTitle && (
          <h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">
            {pageTitle}
            {pageDescription &&
              config.pageTitle &&
              config.pageTitle.description && (
                <>
                  <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                  <small className="text-muted fs-7 fw-bold my-1 ms-1">
                    {pageDescription}
                  </small>
                </>
              )}
          </h1>
        )}
        {/* end::Title */}

        {pageBreadcrumbs &&
          pageBreadcrumbs.length > 0 &&
          config.pageTitle &&
          config.pageTitle.breadCrumbs && (
            <>
              {config.pageTitle.direction === "row" && (
                <span className="h-20px border-gray-200 border-start mx-4"></span>
              )}
              <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                {Array.from(pageBreadcrumbs).map((item, index) => (
                  <li
                    className={clsx("breadcrumb-item", {
                      "text-dark": !item.isSeparator && item.isActive,
                      "text-muted": !item.isSeparator && !item.isActive,
                    })}
                    key={`${item.path}${index}`}
                  >
                    {!item.isSeparator ? (
                      <Link
                        className="text-muted text-hover-primary"
                        to={item.path}
                      >
                        {item.title}
                      </Link>
                    ) : (
                      <span className="bullet bg-gray-200 w-5px h-2px"></span>
                    )}
                  </li>
                ))}
                <li className="breadcrumb-item text-dark">{pageTitle}</li>
              </ul>
            </>
          )}
      </div>

      <div
        className="align-items-center py-1"
        style={{ display: "flex", justifyContent: "end" }}
      >
        {pageTitle == "Estimates List" ? (
          <Link to="/estimates/create" className="btn btn-sm btn-primary">
            <span className="svg-icon svg-icon-2">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mh-50px"
              >
                <rect
                  opacity="0.5"
                  x="11.364"
                  y="20.364"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(-90 11.364 20.364)"
                  fill="currentColor"
                ></rect>
                <rect
                  x="4.36396"
                  y="11.364"
                  width="16"
                  height="2"
                  rx="1"
                  fill="currentColor"
                ></rect>
              </svg>
            </span>
            Add Estimate
          </Link>
        ) : (
          ""
        )}
        {pageTitle == "Clients List" ? (
          <Link to="/client/create" className="btn btn-sm btn-primary">
            <span className="svg-icon svg-icon-2">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mh-50px"
              >
                <rect
                  opacity="0.5"
                  x="11.364"
                  y="20.364"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(-90 11.364 20.364)"
                  fill="currentColor"
                ></rect>
                <rect
                  x="4.36396"
                  y="11.364"
                  width="16"
                  height="2"
                  rx="1"
                  fill="currentColor"
                ></rect>
              </svg>
            </span>
            Add Client
          </Link>
        ) : (
          ""
        )}

        {pageTitle == "Locations List" ? (
          <Link to="/location/create" className="btn btn-sm btn-primary">
            <span className="svg-icon svg-icon-2">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mh-50px"
              >
                <rect
                  opacity="0.5"
                  x="11.364"
                  y="20.364"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(-90 11.364 20.364)"
                  fill="currentColor"
                ></rect>
                <rect
                  x="4.36396"
                  y="11.364"
                  width="16"
                  height="2"
                  rx="1"
                  fill="currentColor"
                ></rect>
              </svg>
            </span>
            Add Location
          </Link>
        ) : (
          ""
        )}

        {pageTitle == "Inventory List" ? (
          <Link to="/inventory/create" className="btn btn-sm btn-primary">
            <span className="svg-icon svg-icon-2">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mh-50px"
              >
                <rect
                  opacity="0.5"
                  x="11.364"
                  y="20.364"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(-90 11.364 20.364)"
                  fill="currentColor"
                ></rect>
                <rect
                  x="4.36396"
                  y="11.364"
                  width="16"
                  height="2"
                  rx="1"
                  fill="currentColor"
                ></rect>
              </svg>
            </span>
            Add Inventory
          </Link>
        ) : (
          ""
        )}

        {pageTitle == "Contact List" ? (
          <Link to="/contact/create" className="btn btn-sm btn-primary">
            <span className="svg-icon svg-icon-2">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mh-50px"
              >
                <rect
                  opacity="0.5"
                  x="11.364"
                  y="20.364"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(-90 11.364 20.364)"
                  fill="currentColor"
                ></rect>
                <rect
                  x="4.36396"
                  y="11.364"
                  width="16"
                  height="2"
                  rx="1"
                  fill="currentColor"
                ></rect>
              </svg>
            </span>
            Add Contact
          </Link>
        ) : (
          ""
        )}
        {pageTitle === "HF Work Order List" ? (
          <Link to="/hf-order/create" className="btn btn-sm btn-primary">
            <span className="svg-icon svg-icon-2">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mh-50px"
              >
                <rect
                  opacity="0.5"
                  x="11.364"
                  y="20.364"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(-90 11.364 20.364)"
                  fill="currentColor"
                ></rect>
                <rect
                  x="4.36396"
                  y="11.364"
                  width="16"
                  height="2"
                  rx="1"
                  fill="currentColor"
                ></rect>
              </svg>
            </span>
            Add Work Order
          </Link>
        ) : (
          ""
        )}

        {pageTitle == "Users List" && userRole === "ADMIN" ? (
          <Link to="/users/create" className="btn btn-sm btn-primary">
            <span className="svg-icon svg-icon-2">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mh-50px"
              >
                <rect
                  opacity="0.5"
                  x="11.364"
                  y="20.364"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(-90 11.364 20.364)"
                  fill="currentColor"
                ></rect>
                <rect
                  x="4.36396"
                  y="11.364"
                  width="16"
                  height="2"
                  rx="1"
                  fill="currentColor"
                ></rect>
              </svg>
            </span>
            Add User
          </Link>
        ) : (
          ""
        )}

        {/* <a
          className='btn btn-sm btn-primary cursor-pointer'
          
        >
        </a> */}
      </div>
    </div>
  );
};

export { DefaultTitle };
