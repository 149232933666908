import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ACTION_generateEstimatePdf } from "../../../../store/estimate/actions";
import { Col, Row } from "react-bootstrap";

import DeleteEstimateModal from "../../Estimates/delete/DeleteEstimateModal";
import { ACTION_getUserById } from "../../../../store/users/actions";

const UserView: React.FC = () => {
  const dispatch: any = useDispatch();
  const navigate: any = useNavigate();

  const loggedInUser = useSelector((state: any) => state.auth.user);

  let currentUser = useSelector((state: any) => state.users.userDetail);

  const [activeEstimate, setActiveEstimate] = useState<any>(null);
  const [showDeleteModal, setShowDeleteModal] = useState<any>(false);

  useEffect(() => {
    const id = window.location.pathname.split("/")[3];
    dispatch(ACTION_getUserById(id));
  }, []);

  // console.log("In VIEW",workList[0]?.Data?.Number);

  // useEffect(() => {
  //   if (userDailyCostBreakDownList && userDailyCostBreakDownList.length) {
  //     const _userDailyRateCostBreakdownIds: any[] = [];
  //     userDailyCostBreakDownList.forEach((element: any) => {
  //       _userDailyRateCostBreakdownIds.push(element._id);
  //     });
  //     setUserDailyRateCostBreakdownIds(_userDailyRateCostBreakdownIds);
  //   } else {
  //     setUserDailyRateCostBreakdownIds([]);
  //   }
  // }, [userDailyCostBreakDownList]);

  // useEffect(() => {
  //   if (formik.values.client) {
  //     let filterClient = _find(_get(clientsData, "clients", []), {
  //       _id: formik.values.client
  //     });
  //     if (filterClient && filterClient?.contacts) {
  //       setContacts(filterClient?.contacts);
  //     } else {
  //       setContacts([]);
  //     }
  //   }
  // }, [formik.values.client]);

  const showPlainDescription = (description) => {
    const withoutHtmlTagsDescription = description.replace(/<[^>]+>/g, "");
    return withoutHtmlTagsDescription.replaceAll("&nbsp;", "");
  };

  const showDeleteModalHandler = (_estimate: any) => {
    setActiveEstimate(_estimate);
    setShowDeleteModal(true);
  };

  const closeDeleteModalHandler = () => {
    setActiveEstimate(null);
    setShowDeleteModal(false);
  };

  const onDownloadBtnHandler = (_id: string) => {
    dispatch(ACTION_generateEstimatePdf(_id));
  };

  return (
    <>
      {showDeleteModal && (
        <DeleteEstimateModal
          closeDeleteModalHandler={closeDeleteModalHandler}
          clientsData={activeEstimate}
        />
      )}

      {/* //// */}
      <>
        <form className="form">
          <div className="fv-row mb-7">
            <Row style={{ marginBottom: "50px" }}>
              <Col>
                <div className="fv-row mb-7">
                  <label className="fw-bold fs-6 mb-2">First Name</label>
                  <p>{currentUser?.FirstName}</p>
                </div>

                <div className="fv-row mb-7">
                  <label className="fw-bold fs-6 mb-2">Role</label>
                  <p>{currentUser?.role}</p>
                </div>
              </Col>

              <Col>
                <div className="fv-row mb-7">
                  <label className="fw-bold fs-6 mb-2">Last Name</label>
                  <p>{currentUser?.LastName}</p>
                </div>
                <div className="fv-row mb-7">
                  <label className="fw-bold fs-6 mb-2">Email</label>
                  <p>{currentUser?.email}</p>
                </div>
              </Col>

              
                <div className="fv-row mb-7">
                  <label className="fw-bold fs-6 mb-2">Status</label>
                  <p>{currentUser?.status}</p>
                </div>
                <div className="fv-row mb-7">
                <label className="fw-bold fs-6 mb-2">Timezone</label>
                <p>{currentUser?.userTimezone}</p>
              </div>
              

              <div className="fv-row mb-7">
                <label className="fw-bold fs-6 mb-2">Corrigo Id</label>
                {currentUser?.corrigoId?.length > 0 ? (
                  <p>{currentUser?.corrigoId}</p>
                ) : (
                  <p>Null</p>
                )}
              </div>

              {/* <Col>
                <div className="fv-row mb-7">
                  <label className="fw-bold fs-6 mb-2">Status</label>
                  <p>{formik.values?.status}</p>
                </div>
                <div className="fv-row mb-7">
                  <label className="fw-bold fs-6 mb-2">Title</label>
                  <p>{formik.values?.title}</p>
                </div>
              </Col>
              <div className="fv-row mb-7">
                <label className="fw-bold fs-6 mb-2">Role</label>
                <p>{formik.values?.role}</p>
              </div> */}
            </Row>

            <Row>
              {(loggedInUser?._id === currentUser?._id ||
                loggedInUser.role === "ADMIN") && (
                <Col xs={6} md={6} lg={6}>
                  <div className="text-end pt-15">
                    <button
                      type="button"
                      className="btn btn-danger"
                      style={{
                        width: "100%",
                      }}
                      onClick={() => showDeleteModalHandler(currentUser?._id)}
                    >
                      Delete
                    </button>
                  </div>
                </Col>
              )}
              {(loggedInUser?._id === currentUser?._id ||
                loggedInUser.role === "ADMIN") && (
                <Col xs={6} md={6} lg={6}>
                  <div className="text-end pt-15">
                    <button
                      type="button"
                      className="btn btn-primary"
                      style={{
                        width: "100%",
                      }}
                      onClick={() =>
                        navigate(`/users/update/${currentUser?._id}`)
                      }
                    >
                      Edit
                    </button>
                  </div>
                </Col>
              )}
            </Row>
          </div>
        </form>
      </>

      {/* //// */}
    </>
  );
};

export default UserView;
