export const GET_WORK_ORDER_LIST = "GET_WORK_ORDER_LIST";
export const GET_ALL_WORK_ORDER_LIST = "GET_ALL_WORK_ORDER_LIST";
export const GET_HF_WORK_ORDER_LIST = "GET_HF_WORK_ORDER_LIST";
export const GET_SINGLE_WORK_ORDER = "GET_SINGLE_WORK_ORDER";
export const GET_PROPERTY_LIST = "GET_PROPERTY_LIST";
export const ASSETS_LIST = "ASSETS_LIST";
export const GET_TASK_LIST = "GET_TASK_LIST";
export const GET_EMPLOYEE_LIST = "GET_EMPLOYEE_LIST";
export const GET_SINGLE_EMPLOYEE = "GET_SINGLE_EMPLOYEE";
export const GET_CONTACTS_LIST = "GET_CONTACTS_LIST";
export const GET_CONTACT_INFO = "GET_CONTACT_INFO";
export const GET_DOCUMENT_INFO = "GET_DOCUMENT_INFO";
export const DELETE_WORKORDER = "DELETE_WORKORDER";

