import apiClient from "../../utils/axios";
import { Dispatch } from "redux";
import * as actionTypes from "./types";
import { toastify } from "../../components/toastify/toastify";
import { uploadFileToS3 } from "../../utils/uploadFileToS3";


export const ACTION_getWorkOrdersAPI = () => (dispatcher: Dispatch<any>) => {
  apiClient
    .get("/corrigo?page=undefined&rows=undefined")
    .then((res) => {
      console.log("work order data res : ", res);
      if (res.data.code === 200) {
        dispatcher({
          type: actionTypes.GET_WORK_ORDER_LIST,
          payload: res?.data?.data?.Entities || [],
        });
        // toastify.Success(res.data.message);
      } else {
        toastify.Error("Something went wrong! Please try again.");
      }
    })
    .catch((error) => console.log("error: ", error));
};

export const ACTION_getHFWorkOrderByIdAPI =
  (id) => (dispatcher: Dispatch<any>) => {
    apiClient
      .get("/hfWorkOrder/" + id)
      .then((res) => {
        console.log("work order data res : ", res);
        if (res.data.code === 200) {
          // const resObj = res?.data?.data?.Entities[0]?.Data
          dispatcher({
            type: actionTypes.GET_SINGLE_WORK_ORDER,
            payload: res?.data?.data
            // payload: {...resObj,Documents: resObj.Documents.map(doc => {
            //   return {...doc, IsPublic: true}
            // })},
          });
          // toastify.Success(res.data.message);
        } else {
          toastify.Error("Something went wrong! Please try again.");
        }
      })
      .catch((error) => console.log("error: ", error));
  };

  export const ACTION_getWorkOrderByIdAPI =
  (id) => (dispatcher: Dispatch<any>) => {
    apiClient
      .get("/corrigo/" + id)
      .then((res) => {
        console.log("work order data res : ", res);
        if (res.data.code === 200) {
          // const resObj = res?.data?.data?.Entities[0]?.Data
          dispatcher({
            type: actionTypes.GET_SINGLE_WORK_ORDER,
            payload: res?.data?.data?.Entities[0]?.Data
            // payload: {...resObj,Documents: resObj.Documents.map(doc => {
            //   return {...doc, IsPublic: true}
            // })},
          });
          // toastify.Success(res.data.message);
        } else {
          toastify.Error("Something went wrong! Please try again.");
        }
      })
      .catch((error) => console.log("error: ", error));
  };

export const ACTION_getWorkOrders = () => (dispatcher: Dispatch<any>) => {
  apiClient
    .get("/corrigo")
    .then((res) => {
      console.log("work order data res : ", res);
      if (res.data.code === 200) {
        dispatcher({
          type: actionTypes.GET_WORK_ORDER_LIST,
          payload: res?.data?.data?.Entities || [],
        });
        // toastify.Success(res.data.message);
      } else {
        toastify.Error("Something went wrong! Please try again.");
      }
    })
    .catch((error) => console.log("error: ", error));
};

export const ACTION_getAllWorkOrders = () => (dispatcher: Dispatch<any>) => {
  apiClient
    .get("/corrigo/allWorkorders")
    .then((res) => {
      console.log("ALL work order data res : ", res);
      if (res.data.code === 200) {
        const allWorkOrders = res?.data?.data?.Entities.map(wo => wo?.Data);
        dispatcher({
          type: actionTypes.GET_ALL_WORK_ORDER_LIST,
          payload: allWorkOrders || [],
        });
        // toastify.Success(res.data.message);
      } else {
        toastify.Error("Something went wrong! Please try again.");
      }
    })
    .catch((error) => console.log("error: ", error));
};

export const ACTION_getAllHFWorkOrders = () => (dispatcher: Dispatch<any>) => {
  apiClient
    .get("/hf-workorder")
    .then((res) => {
      console.log("ALL HF work order data res : ", res);
      if (res.data.code === 200) {
        dispatcher({
          type: actionTypes.GET_HF_WORK_ORDER_LIST,
          payload: res?.data?.data?.workOrders || [],
        });
        // toastify.Success(res.data.message);
      } else {
        toastify.Error("Something went wrong! Please try again.");
      }
    })
    .catch((error) => console.log("error: ", error));
};

export const ACTION_getHFWorkOrders = () => (dispatcher: Dispatch<any>) => {
  apiClient
    .get("/hf-workorder-new")
    .then((res) => {
      console.log("HF work order data res : ", res);
      if (res.data.code === 200) {
        dispatcher({
          type: actionTypes.GET_HF_WORK_ORDER_LIST,
          payload: res?.data?.data?.workOrders || [],
        });
        // toastify.Success(res.data.message);
      } else {
        toastify.Error("Something went wrong! Please try again.");
      }
    })
    .catch((error) => console.log("error: ", error));
};

export const ACTION_getPropertiesAPI = () => (dispatcher: Dispatch<any>) => {
  apiClient
    .get("/corrigo/propertiesList")
    .then((res) => {
      if (res.data.code === 200) {
        dispatcher({
          type: actionTypes.GET_PROPERTY_LIST,
          payload: res?.data?.data?.Entities || [],
        });
        // toastify.Success(res.data.message);
      } else {
        toastify.Error("Something went wrong! Please try again.");
      }
    })
    .catch((error) => console.log("error: ", error));
};

export const ACTION_assetsListAPI =
  (reqPacker: any) => (dispatcher: Dispatch<any>) => {
    apiClient
      .post("/corrigo/assetList", reqPacker)
      .then((res) => {
        if (res.data.code === 200) {
          dispatcher({
            type: actionTypes.ASSETS_LIST,
            payload: res?.data?.data?.Entities || [],
          });
          // toastify.Success(res.data.message);
        } else {
          toastify.Error("Something went wrong! Please try again.");
        }
      })
      .catch((error) => console.log("error: ", error));
  };

export const ACTION_getTaskAPI =
  (modelId: string) => (dispatcher: Dispatch<any>) => {
    apiClient
      .get("/corrigo/task/" + modelId)
      .then((res) => {
        if (res.data.code === 200) {
          dispatcher({
            type: actionTypes.GET_TASK_LIST,
            payload: res?.data?.data?.Entities || [],
          });
          // toastify.Success(res.data.message);
        } else {
          toastify.Error("Something went wrong! Please try again.");
        }
      })
      .catch((error) => console.log("error: ", error));
  };

export const ACTION_getContactInfoById = async (_id: string) => {
  const res = await apiClient.get("/corrigo/contactInfo/" + _id);
  if (res.status === 200) {
    return res.data.data.Data;
  } else {
    console.log("No contact info is found with id " + _id);
  }
};

export const ACTION_getEmployeeAPI = () => (dispatcher: Dispatch<any>) => {
  apiClient
    .get("/corrigo/employeeList")
    .then((res) => {
      if (res.data.code === 200) {
        dispatcher({
          type: actionTypes.GET_EMPLOYEE_LIST,
          payload: res?.data?.data?.Entities || [],
        });
        // toastify.Success(res.data.message);
      } else {
        toastify.Error("Something went wrong! Please try again.");
      }
    })
    .catch((error) => console.log("error: ", error));
};

export const ACTION_getContactListAPI =
  (propertyId: any) => (dispatcher: Dispatch<any>) => {
    apiClient
      .get("/corrigo/customerList/" + propertyId)
      .then((res) => {
        if (res.data.code === 200) {
          dispatcher({
            type: actionTypes.GET_CONTACTS_LIST,
            payload: res?.data?.data?.Entities || [],
          });
          // toastify.Success(res.data.message);
        } else {
          toastify.Error("Something went wrong! Please try again.");
        }
      })
      .catch((error) => console.log("error: ", error));
  };

export const ACTION_createWorkOrder =
  (reqPacket: any, navigate: any) => (dispatcher: Dispatch<any>) => {
    apiClient
      .post("/corrigo/create-work-order", reqPacket)
      .then((res) => {
        if (res.data.code === 200) {
          toastify.Success(res.data.message);
          navigate("/order/list");
        } else {
          toastify.Error("Something went wrong! Please try again.");
        }
      })
      .catch((error) => console.log("error: ", error));
  };


  export const ACTION_updateWorkOrder = (reqPacket: any, navigate: any) => () => {
    apiClient
      .patch("/hf-workorder", reqPacket)
      .then((res) => {
        if (res.data.code === 200) {
          toastify.Success(res.data.message)
          navigate("/hf-order/list")
        } else {
          toastify.Error("Something went wrong! Please try again.")
        }
      })
      .catch((error) => console.log("error: ", error))
  }


  export const ACTION_createHFWorkOrder =
  (reqPacket: any,formData ,navigate: any) => (dispatcher: Dispatch<any>) => {
    apiClient
      .post("/hf-workorder", reqPacket)
      .then((res) => {
        console.log('create work order res :', res)
        if (res.status == 201) {
          toastify.Success(res.data.message);
          uploadFileToS3(formData, res.data.data._id);
        } else {
          toastify.Error("Something went wrong! Please try again.");
        }
      })
      .catch((error) => console.log("error: ", error));
  };
  

  export const ACTION_deleteHFWorkOrder =
	(_id: string, navigate) => (dispatcher: Dispatch<any>) => {
		apiClient
			.delete(`/hfWorkOrder/${_id}`)
			.then((res) => {
				if (res.data.code === 200) {
					dispatcher({
						type: actionTypes.DELETE_WORKORDER,
						payload: { _id },
					})
          navigate('/hf-order/list')
					toastify.Success(res.data.message);
          
				} else {
					toastify.Error("Something went wrong! Please try again.")
				}
			})
			.catch((error) => console.log("error: ", error))
	}