import React, { useEffect, useMemo, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import axios from "axios";
import { type } from "os";
import { useDispatch, useSelector } from "react-redux";
import { ACTION_getWorkOrdersAPI } from "../../../store/workorder/actions";

const localizer = momentLocalizer(moment);

const CalendarItem = ({ setDate, getWorkOrder, workOrderList }: any) => {
  const dispatch: any = useDispatch();
  const [workOrderEventList, setWorkOrderEventList] = useState<any>([]);

  useEffect(() => {
    let eventArray: any[] = [];
    if (workOrderList.length) {
      workOrderList.forEach((data: any) => {
        let obj = {
          title: (
            // <span style={{ backgroundColor: "red" }}>
            //   {`${data.Data.Number} ${data.Data.PoNumber}` || ""}
            // </span>

            <div
              onClick={() => getWorkOrder(data.Data.Number)}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span>{data.Data.Number}</span>
              <span
                style={{
                  marginLeft: "2px",
                  width: "35px",
                  fontSize: "10px",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
                className={`${data.Data.PoNumber ? "Completed" : "InProgress"}`}
              >
                PO
              </span>
            </div>
          ),
          allDay: false,
          start: moment(data.Data.ScheduledStartUtc),
          end: moment(data.Data.ScheduledStartUtc),
        };
        if (data.Data.ScheduledStartUtc) eventArray.push(obj);
      });
      console.log({ eventArray });
      setWorkOrderEventList(eventArray);
    }
  }, [workOrderList]);

  console.log({ workOrderEventList });

  return (
    <div className="card card-xl-stretch mb-xl-8">
      <div className="card-body pt-5">
        <Calendar
          popup={true}
          localizer={localizer}
          events={workOrderEventList || []}
          views={["month"]}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 600 }}
          onNavigate={(date) => {
            setDate(date);
            console.info("----------------------------");
            console.info("date =>", date);
            console.info("----------------------------");
          }}
        />
      </div>
    </div>
  );
};

export default CalendarItem;
