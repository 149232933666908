export const GET_USER_DAILY_COST_BREAK_DOWN_LIST =
  "GET_USER_DAILY_COST_BREAK_DOWN_LIST";
export const GET_DAILY_COST_BREAK_DOWN_LIST = "GET_DAILY_COST_BREAK_DOWN_LIST";
export const ADD_USER_DAILY_COST_BREAK_DOWN_LIST =
  "ADD_USER_DAILY_COST_BREAK_DOWN_LIST";
export const UPDATE_USER_DAILY_COST_BREAK_DOWN_LIST =
  "UPDATE_USER_DAILY_COST_BREAK_DOWN_LIST";
export const DELETE_USER_DAILY_COST_BREAK_DOWN_LIST =
  "DELETE_USER_DAILY_COST_BREAK_DOWN_LIST";
export const DELETE_USER_DAILY_COST_BREAK_DOWN_LIST_FROM_ESTIMATE =
  "DELETE_USER_DAILY_COST_BREAK_DOWN_LIST_FROM_ESTIMATE";

export const GET_ESTIMATE_LIST = "GET_ESTIMATE_LIST";
export const GET_ESTIMATE_DETAIL = "GET_ESTIMATE_DETAIL";
export const DELETE_ESTIMATE = "DELETE_ESTIMATE";
