import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTable } from "react-table";
import ToggleSwitch from "../../../../../components/ToggleSwitch/toggle";
import { ACTION_getClients } from "../../../../../store/client/actions";
import { MenuComponent } from "../../../../../_metronic/assets/ts/components";
import { KTCard, KTCardBody, KTSVG } from "../../../../../_metronic/helpers";
import { Item1 } from "../../../../../_metronic/partials/content/activity/Item1";
import TableContainer from "./TableContainer";

const ActionsButtons = (props: any) => {
  useEffect(() => {
    MenuComponent.reinitialization();
  }, []);

  // console.info("----------------------------");
  // console.info("props =>", props);
  // console.info("----------------------------");

  return (
    <>
      <a
        href="#"
        className="btn btn-light btn-active-light-primary btn-sm"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
      >
        Actions
        <KTSVG
          path="/media/icons/duotune/arrows/arr072.svg"
          className="svg-icon-5 m-0"
        />
      </a>
      <div
        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4"
        data-kt-menu="true"
      >
        <div className="menu-item px-3">
          <Link
            className="menu-link px-3"
            to={`/location/update/${props.locationId}`}
          >
            Edit
          </Link>
        </div>
        <div className="menu-item px-3">
          <a
            className="menu-link px-3"
            data-kt-users-table-filter="delete_row"
            onClick={() => props.showDeleteModalHandler(props.locationId)}
          >
            Delete
          </a>
        </div>
      </div>
    </>
  );
};

const UserTable = ({ usersList = [], showDeleteModalHandler }: any) => {
  const dispatch = useDispatch<any>();

  const  [activeSwitch, setActiveSwitch] = useState(true)

  // const [isCorrigoList, setIsCorrigoList] = useState(false);
  // const [allUsersList, setAllUsersList] = useState(usersList);

  const clientList = useSelector(
    (state: any) => state.client.clientsData.clients
  );

  // useEffect(() => {
  //   if (isCorrigoList) {
  //     setAllUsersList(usersList?.filter((user) => user?.corrigoId));
  //   } else {
  //     setAllUsersList(usersList?.filter((user) => !user?.corrigoId));
  //   }
  // }, [isCorrigoList]);

  const [clientsList, setClientsList] = useState([]);

  useEffect(() => {
    dispatch(ACTION_getClients());
  }, []);

  useEffect(() => {
    setClientsList(clientList);
  }, [clientList]);

  // const toggleHandler = () => {
  //   setIsCorrigoList(!isCorrigoList);
  // };

  // console.log({ isCorrigoList });

  const locationColumns: any = [
    {
      Header: "FIRST NAME",
      accessor: "FirstName",
      Cell: ({ value, row }) => {
        // console.log({ row });
        // console.log({ value });
        return (
          <Link
            className="menu-link px-3"
            to={`/users/view/${row?.original?._id}`}
          >
            {value}
          </Link>
        );
      },
    },
    {
      Header: "LAST NAME",
      accessor: "LastName",
      Cell: ({ value, row }) => {
        return (
          <Link
            className="menu-link px-3"
            to={`/users/view/${row?.original?._id}`}
          >
            {value}
          </Link>
        );
      },
    },
    {
      Header: "EMAIL",
      accessor: "email",
    },
    // {
    //   Header: (
    //     <>
    //       {/* <button
    //         onClick={() => {
    //           setIsCorrigoList((prevState) => !prevState);
    //         }}
    //         className="btn btn-sm btn-primary"
    //       >
    //         HF Users
    //       </button> */}
    //     </>
    //   ),
    //   accessor: "ActorTypeId",
    //   Cell: ({ value, row }) => {
    //     // console.log("our users : ", usersList);
    //     return;
    //   },
    // },
  ];


  
  let sortedUsers= usersList.sort(function(a, b) {
    return a.FirstName.localeCompare(b.FirstName);
 });

  const data: any = useMemo(() => sortedUsers, [sortedUsers]);
  const columns: any = useMemo(() => locationColumns, [clientsList]);

  const extraFilters = () => <ToggleSwitch label="Show Active" activeSwitch={activeSwitch} onChange={
    e => {
      setActiveSwitch(e.target.checked)
    }
  } />

  // const filteredData = data.filter(item => item.status === (activeSwitch ? `ACTIVE` : `INACTIVE`))
  const filteredData = data.filter(item => activeSwitch ? item.status === 'ACTIVE' : item.status != 'ACTIVE')

  return (
    <KTCard>
      <KTCardBody className="py-4">
        <div className="table-responsive">
          {data.length > 0 && <TableContainer columns={columns} data={filteredData} extraFilters={extraFilters} />}
        </div>
      </KTCardBody>
    </KTCard>
  );
};

export default UserTable;
