import apiClient from "../../utils/axios";
import { Dispatch } from "redux";
import * as actionTypes from "./types";
import { toastify } from "../../components/toastify/toastify";

export const ACTION_getUsers = () => (dispatcher: Dispatch<any>) => {
  apiClient
    .get("/auth/users")
    .then((res) => {
      console.log("User RES : ", res);
      if (res.data.code === 200) {
        dispatcher({
          type: actionTypes.GET_USERS_LIST,
          payload: res.data.data.users,
        });
        // toastify.Success(res.data.message);
      } else {
        toastify.Error("Something went wrong! Please try again.");
      }
    })
    .catch((error) => console.log("error: ", error));
  // .then((res) => {
  //   console.log("USERS LiST : ", res?.data?.data?.Entities);
  //   if (res.data.code === 200) {
  //     dispatcher({
  //       type: actionTypes.GET_USERS_LIST,
  //       payload: res.data.data.Entities,
  //     });
  //     // toastify.Success(res.data.message);
  //   } else {
  //     toastify.Error("Something went wrong! Please try again.");
  //   }
  // })
  // .catch((error) => console.log("error: ", error));
};

export const ACTION_createUser =
  (reqPacket: any, navigate: any) => (dispatcher: Dispatch<any>) => {
    apiClient
      .post("/client", reqPacket)
      .then((res) => {
        if (res.data.code === 201) {
          toastify.Success(res.data.message);
          navigate("/client/list");
        } else {
          toastify.Error("Something went wrong! Please try again.");
        }
      })
      .catch((error) => console.log("error: ", error));
  };

export const ACTION_getUserById =
  (_id: string) => (dispatcher: Dispatch<any>) => {
    apiClient
      .get("/auth/" + _id)
      .then((res) => {
        if (res.data.code === 200) {
          console.log("MY USER DETAIL : ", res);
          dispatcher({
            type: actionTypes.GET_USER_DETAIL,
            payload: res.data.data,
          });
          // toastify.Success(res.data.message);
        } else {
          toastify.Error("Something went wrong! Please try again.");
        }
      })
      .catch((error) => console.log("error: ", error));
  };

export const ACTION_deleteUser =
  (_id: string) => (dispatcher: Dispatch<any>) => {
    apiClient
      .delete("/client/" + _id)
      .then((res) => {
        if (res.data.code === 200) {
          dispatcher({
            type: actionTypes.DELETE_USER,
            payload: {
              _id,
            },
          });
        } else {
          toastify.Error("Something went wrong! Please try again.");
        }
      })
      .catch((error) => console.log("error: ", error));
  };

export const ACTION_updateUser =
  (reqPacket: any, navigate: any) => (dispatcher: Dispatch<any>) => {
    apiClient
      .patch("/auth/update", reqPacket)
      .then((res) => {
        if (res.data.code === 200) {
          toastify.Success(res.data.message);
          navigate("/users/list");
        } else {
          toastify.Error("Something went wrong! Please try again.");
        }
      })
      .catch((error) => console.log("error: ", error));
  };
