import clsx from "clsx";
import React, { FC } from "react";
import {
  HeaderUserMenu,
  Search,
} from "../../../partials";
import { useLayout } from "../../core";

const toolbarButtonMarginClass = "ms-1 ms-lg-3",
  toolbarButtonHeightClass = "w-30px h-30px w-md-40px h-md-40px",
  toolbarUserAvatarHeightClass = "symbol-30px symbol-md-40px",
  toolbarButtonIconSizeClass = "svg-icon-1";

const Topbar: FC = () => {
  const { config } = useLayout();

  return (
    <div className="d-flex align-items-stretch flex-shrink-0">
      {/* begin::Theme mode */}
      {/* <div
        className={clsx("d-flex align-items-center", toolbarButtonMarginClass)}
      >
        <ThemeModeSwitcher
          toggleBtnClass={clsx(
            "btn-active-light-primary btn-custom",
            toolbarButtonHeightClass
            )}
            />
          </div> */}
      {/* end::Theme mode */}

      <div
        className={clsx("d-flex align-items-stretch", toolbarButtonMarginClass)}
      >
        <Search />
      </div>

      {/* begin::User */}
      <div
        className={clsx("d-flex align-items-center", toolbarButtonMarginClass)}
        id="kt_header_user_menu_toggle"
      >
        {/* begin::Toggle */}
        <div
          className={clsx(
            "cursor-pointer symbol",
            toolbarUserAvatarHeightClass
          )}
          data-kt-menu-trigger="click"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="bottom"
        >
          {/*<img src={toAbsoluteUrl("/media/avatars/300-1.jpg")} alt="metronic" />*/}
          <i
            className="bi bi-person-fill p-0"
            style={{ fontSize: "25px", color: "#000" }}
          />
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

      {/* Search */}

      {/* Activities */}
      {/* begin::Drawer toggle */}
      {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <div
          className={clsx(
            'btn btn-icon btn-active-light-primary btn-custom',
            toolbarButtonHeightClass
          )}
          id='kt_activities_toggle'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen032.svg'
            className={toolbarButtonIconSizeClass}
          />
        </div>
      </div> */}
      {/* end::Drawer toggle */}

      {/* NOTIFICATIONS */}
      {/* begin::Menu- wrapper */}
      {/* <div
        className={clsx("d-flex align-items-center", toolbarButtonMarginClass)}
      >
        <div
          className={clsx(
            "btn btn-icon btn-active-light-primary btn-custom",
            toolbarButtonHeightClass
          )}
          data-kt-menu-trigger="click"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="bottom"
        >
          <KTSVG
            path="/media/icons/duotune/general/gen022.svg"
            className={toolbarButtonIconSizeClass}
          />
        </div>
        <HeaderNotificationsMenu />
      </div> */}
      {/* end::Menu wrapper */}

      {/* CHAT */}
      {/* begin::Menu wrapper */}
      {/* <div
        className={clsx("d-flex align-items-center", toolbarButtonMarginClass)}
      >
        <div
          className={clsx(
            "btn btn-icon btn-active-light-primary btn-custom position-relative",
            toolbarButtonHeightClass
          )}
          id="kt_drawer_chat_toggle"
        >
          <KTSVG
            path="/media/icons/duotune/communication/com012.svg"
            className={toolbarButtonIconSizeClass}
          />

          <span className="bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink"></span>
        </div>
      </div> */}
      {/* end::Menu wrapper */}

      {/* Quick links */}
      {/* begin::Menu wrapper */}
      {/* <div
        className={clsx("d-flex align-items-center", toolbarButtonMarginClass)}
      >
        <div
          className={clsx(
            "btn btn-icon btn-active-light-primary btn-custom",
            toolbarButtonHeightClass
          )}
          data-kt-menu-trigger="click"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="bottom"
        >
          <KTSVG
            path="/media/icons/duotune/general/gen025.svg"
            className={toolbarButtonIconSizeClass}
          />
        </div>
        <QuickLinks />
      </div> */}
      {/* end::Menu wrapper */}

      {/* begin::Aside Toggler */}
      {/* {config.header.left === "menu" && (
        <div
          className="d-flex align-items-center d-lg-none ms-2 me-n3"
          title="Show header menu"
        >
          <div
            className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
            id="kt_header_menu_mobile_toggle"
          >
            <KTSVG
              path="/media/icons/duotune/text/txt001.svg"
              className="svg-icon-1"
            />
          </div>
        </div>
      )} */}
    </div>
  );
};

export { Topbar };
