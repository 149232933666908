import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import _get from "lodash/get";
import _find from "lodash/find";
import { useNavigate } from "react-router-dom";
import "react-dropdown-tree-select/dist/styles.css";

// import { ACTION_postWorkOrder } from "../../../../store/workOrder/actions"
import { Button, Col, Row } from "react-bootstrap";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import CustomDropdown from "../../../../components/CustomDropdown/CustomDropdown";
import {
  ACTION_assetsListAPI,
  ACTION_createHFWorkOrder,
  ACTION_createWorkOrder,
  ACTION_getAllHFWorkOrders,
  ACTION_getContactListAPI,
  ACTION_getEmployeeAPI,
  ACTION_getPropertiesAPI,
  ACTION_getTaskAPI,
} from "../../../../store/workorder/actions";
import DropdownTreeSelect from "react-dropdown-tree-select";
import _ from "lodash";
import "../index.css";
import { ACTION_getClients } from "../../../../store/client/actions";
import { ACTION_getLocationList } from "../../../../store/location/actions";
import { ACTION_getUsers } from "../../../../store/users/actions";
import { constants } from "buffer";
import moment from "moment";

const editWorkOrdersSchema = Yup.object().shape({
  // StatusId: Yup.string().required("StatusId is required"),
  // wo: Yup.string().required("WO# is required"),
  // po: Yup.string().required("PO# is required"),
  space: Yup.string(),
  // scheduleTo: Yup.date().required("Date is required"),
  ShortLocation: Yup.string(),
  task: Yup.string(),

  // TaskRefinement: Yup.string().required("TaskRefinement is required"),
  // Employee: Yup.string().required("Assign Employee name is required"),
});

export const statusDropdownData = [
  {
    label: "New",
    value: "New",
  },
  {
    label: "OnHold",
    value: "OnHold",
  },
  {
    label: "InProgress",
    value: "InProgress",
  },
  {
    label: "Completed",
    value: "Completed",
  },
  {
    label: "Attention",
    value: "Attention",
  },
  {
    label: "Unknown",
    value: "Unknown",
  },
  {
    label: "Open",
    value: "Open",
  },
  {
    label: "Paused",
    value: "Paused",
  },
  {
    label: "Cancelled",
    value: "Cancelled",
  },
  {
    label: "Closed",
    value: "Closed",
  },
];

export const PriorityDropdownData = [
  {
    label: "Emergency",
    Id: 1,
  },
  {
    label: "Regular",
    Id: 2,
  },
  {
    label: "Low",
    Id: 3,
  }
];

export const flagDropdownData = [
  {
    DisplayAs: "Request Needs Customer Approval",
    value: "1",
    Hide: false,
  },
  {
    DisplayAs: "Other",
    value: "2",
    Hide: false,
  },
  {
    DisplayAs: "Estimate Needs Customer Approval",
    value: "3",
    Hide: false,
  },
  {
    DisplayAs: "Waiting for Estimate",
    value: "4",
    Hide: false,
  },
  {
    DisplayAs: "Dependency",
    value: "5",
    Hide: false,
  },
  {
    DisplayAs: "Deferred",
    value: "6",
    Hide: false,
  },
  {
    DisplayAs: "Ready to Invoice",
    value: "7",
    Hide: false,
  },
  {
    DisplayAs: "Needs Requestor Review",
    value: "8",
    Hide: false,
  },
  {
    DisplayAs: "CFM Approval Required",
    value: "9",
    Hide: false,
  },
  {
    DisplayAs: "Customer Cancel Request",
    value: "10",
    Hide: false,
  },
  {
    DisplayAs: "Rejected/recalled",
    value: "11",
    Hide: false,
  },
  {
    DisplayAs: "Message Received",
    value: "12",
    Hide: false,
  },
  {
    DisplayAs: "Quote submitted",
    value: "13",
    Hide: false,
  },
  {
    DisplayAs: "Estimate Requested",
    value: "14",
    Hide: false,
  },
  {
    DisplayAs: "Estimate Rejected",
    value: "15",
    Hide: false,
  }
];


const validFileTypes = ['image/jpg', 'image/jpeg', 'image/png'];

const WorkOrderForm: React.FC = () => {
  const dispatch: any = useDispatch();
  const navigate: any = useNavigate();

  const {  assetList, taskList } =
    useSelector((state: any) => state.workOrder);
  const { user } = useSelector((state: any) => state.auth);
  const workOrderList = useSelector((state: any) => state.workOrder.allHFWorkordersList);
  const [StatusId, setStatusId] = useState("New");
  const [Priority, setPriority] = useState("1");
  const [space, setSpace] = useState<any>();
  const [task, setTask] = useState<any>();
  const [taskData, setTaskData] = useState<any>();

  const [contact, setContact] = useState();
  const [currentClient, setCurrentClient] = useState<any>({});
  const [assignment, setAssignment] = useState();
  const [flag, setFlag] = useState();
  const [assetData, setAssetData] = useState<any[]>([]);
  const [ShortLocation, setShortLocation] = useState<any>();
  const clientsList = useSelector(
    (state: any) => state.client.clientsData.clients?.filter(x => x.status == "ACTIVE")
  );
  const [activeUserList, setActiveUserList] = useState<any>([])
  const locationList = useSelector((state: any) => state.location.locationsData);
  const usersList = useSelector((state: any) => state.users.usersList);
  const [error, setError] = useState('');
  const [formData, setFormData] = useState<any>();


  // const {
  //   data: imageUrls = [],
  //   isLoading: imagesLoading,
  //   error: fetchError,
  // } = useQueryHook('/document/upload', refetch);

  const [locations, setLocations] = useState<any[]>();

  useEffect(() => {
    dispatch(ACTION_getUsers());
    dispatch(ACTION_getPropertiesAPI());
    dispatch(ACTION_getClients());
    dispatch(ACTION_getLocationList())
  }, []);


  useEffect(()=> {
    const activeUsers = usersList?.filter((user)=> user.status === 'ACTIVE')
    setActiveUserList(activeUsers)
  },[usersList])

  useEffect(() => {
   const locationData = locationList?.filter(loc => { 
    if(loc?.Address) return Object.keys(loc?.Address).length > 0 })
    setLocations(locationData)
  }, [locationList]);

  const fileUploadHandler =async (e) => {
    const file = e.target.files[0];
    console.log("file  :", file)
    // if(!validFileTypes.find(type => type === file.type)){
    //   setError("File must be JPG/PNG format");
    //   return
    // }

    const form = new FormData();
    form.append("image",file)

    setFormData(form);
  }

  useEffect(() => {
    let parentArray: any[] = [];
    assetList &&
      assetList.map((asset: any) => {
        if (asset?.Data?.Distance === 1) {
          if (
            asset.Data.Id === ShortLocation?.value &&
            asset.Data.Child.Name === ShortLocation?.label
          ) {
            let parent: {
              label: string;
              value: string;
              checked: boolean;
              children: any[];
            } = {
              label: asset?.Data?.Child?.Name,
              value: asset?.Data?.Id,
              checked: true,
              children: [],
            };
            parentArray.push(parent);
          } else {
            let parent: {
              label: string;
              value: string;
              children: any[];
            } = {
              label: asset?.Data?.Child?.Name,
              value: asset?.Data?.Id,
              children: [],
            };
            parentArray.push(parent);
          }
        }
      });

    let childrenArray: any[] = [];
    parentArray?.length !== 0 &&
      parentArray.map((parent) => {
        assetList.map((asset) => {
          if (
            asset?.Data?.Distance === 2 &&
            asset?.Data?.ParentId === parent?.value
          ) {
            if (
              asset.Data.Child.Id === ShortLocation?.value &&
              asset.Data.Child.Name === ShortLocation?.label
            ) {
              let children: {
                label: string;
                value: string;
                checked: boolean;
              } = {
                label: asset?.Data?.Child?.Name,
                value: asset?.Data?.Child?.Id,
                checked: true,
              };
              childrenArray.push(children);
            } else {
              let children: {
                label: string;
                value: string;
              } = {
                label: asset?.Data?.Child?.Name,
                value: asset?.Data?.Child?.Id,
              };
              childrenArray.push(children);
            }
          }
        });
        parent.children = _.uniqBy(childrenArray, "value");
      });

    setAssetData(parentArray);
  }, [assetList, ShortLocation]);

  useEffect(() => {
    let array: any[] = [];
    taskList &&
      taskList.map((task: any) => {
        let obj = {
          label: task?.Data.DisplayAs,
          value: task?.Data?.Id,
        };
        array.push(obj);
      });
    setTaskData(array);
  }, [taskList]);

  const formik = useFormik({
    initialValues: {
      StatusId: "New",
      wo: "",
      po: "",
      scheduleTo: "",
      ShortLocation: "",
      Priority:  {
        label: "Regular",
        value: 2,
      },
      Customer: "",
      Flag: null,
      TaskRefinement: "",
      ContactName: "",
      ContactAddress: "",
      Employee: "",
    },
    validationSchema: editWorkOrdersSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      handleSubmitWorkOrderForm();
    },
  });

  const handleSubmitWorkOrderForm = async () => {
    const Numberformat =
      formik?.values?.ShortLocation?.split(/[\s,-]+/)[0].toUpperCase() +
      Math.round(100 + Math.random() * (999 - 100));
      const spaceFormat = currentClient?.Name + "\\" + formik?.values.ShortLocation
      const start = moment.tz(formik.values.scheduleTo, "UTC");
    const userId = user._id;
    const StatusId = formik.values.StatusId;
    const Number = Numberformat;
    const Customer = formik.values.Customer;
    const poNumber = formik.values.po;
    const ShortLocation = spaceFormat;
    const ScheduledStartUtc = start.tz("UTC").format("YYYY-MM-DDThh:mm");
    const Priority = formik.values.Priority;
    const Flag = formik.values.Flag;
    const TaskRefinement = formik.values.TaskRefinement;
    const Contact = formik.values.ContactName;
    const Employee = formik.values.Employee;
    const ContactAddress = formik.values.ContactAddress;

    let reqData = {
      userId,
      StatusId,
      Number,
      poNumber,
      Customer,
      // ShortLocation,
      ShortLocation,
      ScheduledStartUtc,
      Priority,
      Flag,
      TaskRefinement,
      Contact,
      ContactAddress,
      Employee,
    };

    let reqPacker = {
      Command: {
        WorkOrder: {
          PoNumber: poNumber,
          Items: [
            {
              Asset: {
                Id: ShortLocation,
              },
              Task: {
                Id: task,
              },
            },
          ],
          TypeCategory: "BASIC",
          Priority: {
            Id: 1,
          },
          WorkZone: {
            Id: space,
          },
          SubType: {
            Id: 256,
          },
          Customer: {
            Id: Contact,
          },
        },
        ComputeSchedule: true,
        ComputeAssignment: true,
        EmergencyDisabled: false,
        Comment: TaskRefinement,
      },
      RequestId: 1234,
    };  

    console.log({ reqData });
    dispatch(ACTION_createHFWorkOrder(reqData, formData , navigate));
    dispatch(ACTION_getAllHFWorkOrders())
    navigate('/hf-order/list')
  };

  const inputHandler = (event: any, editor: any) => {
    formik.setFieldValue("TaskRefinement", editor.getData());
  };

  

 

  // enum flagDropdownData {
  //   Up = 1,
  //   Down,
  //   Left,
  //   Right,
  // }

 


  const assignObjectPaths = (obj: any, stack: any) => {
    Object.keys(obj).forEach((k) => {
      const node = obj[k];
      if (typeof node === "object") {
        node.path = stack ? `${stack}.${k}` : k;
        assignObjectPaths(node, node.path);
      }
    });
  };
  const onChange = (currentNode, selectedNodes) => {
    console.info("----------------------------");
    console.log("path::", currentNode.path, selectedNodes);
    console.info("----------------------------");
    // dispatch(ACTION_getTaskAPI(selectedNodes[0]?.value));
    dispatch(ACTION_getTaskAPI("1260"));
    setShortLocation({
      label: selectedNodes[0]?.label,
      value: selectedNodes[0]?.value,
    });
    formik.setFieldValue("ShortLocation", selectedNodes[0]?.label);
  };

  console.log({ clientsList });

  const DropDownTree = () => {
    assignObjectPaths(assetData, null);

    return (
      <DropdownTreeSelect
        className="bootstrap-demo"
        mode="radioSelect"
        data={assetData}
        onChange={onChange}
      />
    );
  };

  // console.log({locations})
  // console.log({clientsList})
  console.log({currentClient})
  console.log({usersList})


  return (
    <form className="form" onSubmit={formik.handleSubmit}>
      <div className="fv-row mb-7">
        <Row>
            <div className="fv-row mb-7">
              <label className="required fw-bold fs-6 mb-2">Task</label>
              <input
                style={{
                  backgroundColor: "#F5F8FA",
                  border: "0",
                  outline: "none",
                  height: "43px",
                  borderRadius: "0.475rem",
                  fontWeight: "500",
                  fontSize: "1.1rem",
                  color: "#5E6278",
                  width: "100%",
                }}
                {...formik.getFieldProps("TaskRefinement")}
                name="TaskRefinement"
                className=""
                // value={TaskRefinement}
                type="text"
                onChange={(e) => {
                  setTask(e);
                  formik.setFieldValue("TaskRefinement", e.target.value);
                }}
              />
              {formik.touched.TaskRefinement && formik.errors.TaskRefinement && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.TaskRefinement}</span>
                  </div>
                </div>
              )}
            </div>
          </Row>
          <Row>
            <Col>
              <div className="fv-row mb-7">
                <label className="required fw-bold fs-6 mb-2">Client</label>
                <CustomDropdown
                  {...formik.getFieldProps("Customer")}
                  name="Customer"
                  className=""
                  options={clientsList?.map((item: any) => {
                    return {
                      label: item?.DisplayAs,
                      value: item?._id,
                    };
                  })}
                  onChange={(e) => {
                    const client = clientsList.find(client => client._id === e.value)
                    setCurrentClient(client)
                    formik.setFieldValue("Customer", e.value);
                  }}
                />
                {formik.touched.Customer && formik.errors.Customer && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.Customer}</span>
                    </div>
                  </div>
                )}
              </div>
              </Col>
              <Col>
              <div className="fv-row mb-7">
                <label className="fw-bold fs-6 mb-2">Priority</label>
                <CustomDropdown
                  {...formik.getFieldProps("Priority")}
                  name="Priority"
                  className=""
                  value={{
                    label: "Regular",
                    value: 2,
                  }}
                  options={(PriorityDropdownData as any).map((item: any) => {
                    return {
                      data: item,
                      label: item?.label,
                      value: item?.Id,
                    };
                  })}
                  onChange={(e) => {
                    setPriority(e.label);
                    formik.setFieldValue("Priority", e.data);
                  }}
                />
              </div>
           
            </Col>
          </Row>
          <Row> 
            <Col> 
              <div className="fv-row mb-7">
                <label className="required fw-bold fs-6 mb-2">Location</label>
                <CustomDropdown
                  {...formik.getFieldProps("ShortLocation")}
                  name="ShortLocation"
                  className=""
                  options={currentClient?.Spaces?.map((item: any) => {
                    return {
                      value: item._id,
                      label: item?.locationName,
                    };
                  })}
                  onChange={(e) => {
                    formik.setFieldValue("ShortLocation", e.label);
                  }}
                />
                {formik.touched.ShortLocation && formik.errors.ShortLocation && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.ShortLocation}</span>
                    </div>
                  </div>
                )}
              </div>
              </Col>
              <Col>
              <div className="fv-row mb-7">
                <label className="fw-bold fs-6 mb-2">Flag</label>
                <CustomDropdown
                  {...formik.getFieldProps("Flag")}
                  name="Flag"
                  className=""
                  value={flag}
                  options={(flagDropdownData as any).map((item: any) => {
                    return {
                      data: item,
                      label: item?.DisplayAs,
                      value: item?.value,
                    };
                  })}
                  onChange={(e) => {
                    setFlag(e.label);
                    formik.setFieldValue("Flag", e.data);
                  }}
                />
                {formik.touched.Flag && formik.errors.Flag && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.Flag}</span>
                    </div>
                  </div>
                )}
              </div>
              </Col>
          </Row>
          <Row> 
            <Col> 
            <div className="fv-row mb-7">
              <label className="fw-bold fs-6 mb-2">Contact Name</label>
              <CustomDropdown
                {...formik.getFieldProps("ContactName")}
                name="ContactName"
                className=""
                options={currentClient?.Contacts?.map((item: any) => {
                  return {
                    label: item?.fullName,
                    value: item?._id,
                  };
                })}
                onChange={(e) => {
                  const contactObject = workOrderList?.find(wo => wo.ContactName === e.label)?.ContactAddress
                  formik.setFieldValue("ContactName", e.value);
                  formik.setFieldValue("ContactAddress", contactObject)
                }}
              />
              {formik.touched.ContactName && formik.errors.ContactName && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.ContactName}</span>
                  </div>
                </div>
              )}
            </div>
            <div className="fv-row mb-7">
              <label className="fw-bold fs-6 mb-2">PO#</label>
              <input
                {...formik.getFieldProps("po")}
                type="text"
                placeholder="Enter po number"
                name="po"
                className={clsx(
                  "form-control form-control-solid mb-3 mb-lg-0",
                  {
                    "is-invalid": formik.touched.po && formik.errors.po,
                  },
                  {
                    "is-valid": formik.touched.po && !formik.errors.po,
                  }
                )}
                autoComplete="off"
              />
              {formik.touched.po && formik.errors.po && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.po}</span>
                  </div>
                </div>
              )}
            </div>

            <div className="fv-row mb-7">
              <label className="required fw-bold fs-6 mb-2">Schedule To</label>
              <input
                {...formik.getFieldProps("scheduleTo")}
                type="datetime-local"
                name="scheduleTo"
                className={clsx(
                  "form-control form-control-solid mb-3 mb-lg-0",
                  {
                    "is-invalid":
                      formik.touched.scheduleTo && formik.errors.scheduleTo,
                  },
                  {
                    "is-valid":
                      formik.touched.scheduleTo && !formik.errors.scheduleTo,
                  }
                )}
                autoComplete="off"
              />
              {formik.touched.scheduleTo && formik.errors.scheduleTo && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.scheduleTo}</span>
                  </div>
                </div>
              )}
            </div>
            <div className="fv-row mb-7">
              <label className="fw-bold fs-6 mb-2">Assign To</label>
              <CustomDropdown
                {...formik.getFieldProps("Employee")}
                name="Employee"
                className=""
                value={assignment}
                options={activeUserList?.map((item: any) => {
                  return {
                    label: item?.FirstName,
                    value: item?._id,
                  };
                })}
                onChange={(e) => {
                  setAssignment(e.label);
                  formik.setFieldValue("Employee", e.value);
                }}
              />
              {formik.touched.Employee && formik.errors.Employee && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.Employee}</span>
                  </div>
                </div>
              )}
            </div>
            {/* <div className="fv-row mb-7">
              <label className="fw-bold fs-6 mb-2">Contact Name</label>
              <CustomDropdown
                {...formik.getFieldProps("ContactName")}
                name="ContactName"
                className=""
                options={currentClient?.Contacts?.map((item: any) => {
                  return {
                    label: item?.fullName,
                    value: item?._id,
                  };
                })}
                onChange={(e) => {
                  const contactObject = workOrderList?.find(wo => wo.ContactName === e.label)?.ContactAddress
                  formik.setFieldValue("ContactName", e.value);
                  formik.setFieldValue("ContactAddress", contactObject)
                }}
              />
              {formik.touched.ContactName && formik.errors.ContactName && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.ContactName}</span>
                  </div>
                </div>
              )}
            </div> */}
          </Col>

          <Col>
            {/* <div className="fv-row mb-7">
              <label className="fw-bold fs-6 mb-2">Priority</label>
              <CustomDropdown
                {...formik.getFieldProps("Priority")}
                name="Priority"
                className=""
                value={Priority}
                options={(PriorityDropdownData as any).map((item: any) => {
                  return {
                    data: item,
                    label: item?.label,
                    value: item?.Id,
                  };
                })}
                onChange={(e) => {
                  setPriority(e.label);
                  formik.setFieldValue("Priority", e.data);
                }}
              />
            </div> */}
            {/* <div className="fv-row mb-7">
              <label className="fw-bold fs-6 mb-2">Flag</label>
              <CustomDropdown
                {...formik.getFieldProps("Flag")}
                name="Flag"
                className=""
                value={flag}
                options={(flagDropdownData as any).map((item: any) => {
                  return {
                    data: item,
                    label: item?.DisplayAs,
                    value: item?.value,
                  };
                })}
                onChange={(e) => {
                  setFlag(e.label);
                  formik.setFieldValue("Flag", e.data);
                }}
              />
              {formik.touched.Flag && formik.errors.Flag && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.Flag}</span>
                  </div>
                </div>
              )}
            </div> */}
            <div className="fv-row mb-7">
              <input type="file" accept=".gif,.jpg,.jpeg,.png,.doc,.pdf,.docx" id="fileUpload" hidden onChange={fileUploadHandler}/>
              <Button as="label" htmlFor="fileUpload" ><i className="fa fa-cloud-upload" aria-hidden="true"></i> Upload File</Button>
              {error && <p style={{ fontSize: "lg", color: "red", marginTop: "10px" }} >{error}</p> }

            </div>
            {/* <div className="fv-row mb-7">
              <label className="fw-bold fs-6 mb-2">TaskRefinement</label>
              <CKEditor
                id="inputText"
                type="inline"
                data={formik.values.TaskRefinement}
                className={clsx(
                  "form-control form-control-solid mb-3 mb-lg-0",
                  {
                    "is-invalid":
                      formik.touched.TaskRefinement &&
                      formik.errors.TaskRefinement,
                  },
                  {
                    "is-valid":
                      formik.touched.TaskRefinement &&
                      !formik.errors.TaskRefinement,
                  }
                )}
                editor={ClassicEditor}
                onChange={inputHandler}
              />

              {formik.touched.TaskRefinement && formik.errors.TaskRefinement && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.TaskRefinement}</span>
                  </div>
                </div>
              )}
            </div> */}
          </Col>
        </Row>

        <div className="text-end pt-15">
          <button
            type="reset"
            className="btn btn-light me-3"
            data-kt-users-modal-action="cancel"
            onClick={() => {
              navigate("/hf-order/list");
            }}
          >
            Discard
          </button>

          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </div>
      </div>
    </form>
  );
};

export default WorkOrderForm;
