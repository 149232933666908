import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  ACTION_getEmployeeAPI,
  ACTION_getHFWorkOrderByIdAPI,
} from "../../../../store/workorder/actions";
import { taskRefinementHTML } from "../../../../utils/helpers";
import DeleteEstimateModal from "../delete/DeleteHfWorkOrderModal";
import DeleteWorkOrderModal from "../delete/DeleteHfWorkOrderModal";
import { uploadFileToS3 } from "../../../../utils/uploadFileToS3";

const WorkOrderView = () => {
  const dispatch: any = useDispatch();
  let { orderId = "" } = useParams();
  const navigate = useNavigate();
  const [activeWorkOrder, setActiveWorkOrder] = useState<any>(null);
  const [showDeleteModal, setShowDeleteModal] = useState<any>(false);
  const [loading, setLoading] = useState<boolean>(false)


  const data: any = useSelector(
    (state: any) => state.workOrder.workOrderDetail
  );
  const listOfEmployees = useSelector(
    (state: any) => state.workOrder.employeeList
  );

  const showDeleteModalHandler = (_workOrder: any) => {
    setActiveWorkOrder(_workOrder);
    setShowDeleteModal(true);
  };


  const closeDeleteModalHandler = () => {
    setActiveWorkOrder(null);
    setShowDeleteModal(false);
  };

  useEffect(() => {
    dispatch(ACTION_getHFWorkOrderByIdAPI(orderId));
  }, []);

  const fileUploadHandler = async (e) => {
    setLoading(true)
    const file = e.target.files[0];
    const form = new FormData();
    form.append("image", file)
    await uploadFileToS3(form, data?._id);
    dispatch(ACTION_getHFWorkOrderByIdAPI(orderId))
    setLoading(false)
  }

  useEffect(() => {
    if (data?.Assignments?.length > 1) {
      dispatch(ACTION_getEmployeeAPI());
    }
  }, [data]);

  const start = moment.tz(data?.ScheduledStartUtc, "UTC"); // original timezone
  const onSiteByDate = moment.tz(data?.DtOnSiteBy, "UTC");
  const dueByDate = moment.tz(data?.DtDue, "UTC");
  const acknowledgeByDate = moment.tz(data?.DtAcknowledgeBy, "UTC");


  const uploadBtnText = loading ? <span>Loading....</span> : <span><i className="fa fa-cloud-upload" aria-hidden="true"></i> Upload File</span>

  let spaces = data?.ShortLocation?.split("\\");

  return (
    <>
      {showDeleteModal && (
        <DeleteWorkOrderModal
          closeDeleteModalHandler={closeDeleteModalHandler}
          workOrderData={activeWorkOrder}
        />
      )}
      {data._id !== orderId ? (
        <span></span>
      ) : (
        <div className="border-0 d-flex align-items-center p-5 mb-5 card card-xl-stretch mb-xl-8">
          <Container fluid>
            <Row>
              <Col xs={12} sm={6} md={3} lg={3}>
                <div className="workorder-field">
                  <label className="fs-4">
                    <a
                      target={"_blank"}
                      className="menu-link"
                      href={`https://am-ce99a.corrigo.com/corpnet/workorder/workorderdetails.aspx/${data?.Id}`}
                      rel="noreferrer"
                    >
                      {" "}
                      {data?.Number}{" "}
                    </a>
                  </label>
                  <span className="d-block fs-5 text-muted">WO#</span>
                </div>
              </Col>
              <Col xs={12} sm={6} md={3} lg={3}>
                <div className="workorder-field">
                  <label className="fs-4">
                    {data?.StatusId == "New" ? (
                      <i className="bi bi-file-plus-fill fs-0" title="New"></i>
                    ) : (
                      <i
                        className="bi bi-file-check-fill fs-0"
                        title="Completed"
                      ></i>
                    )}
                    {data?.StatusId}
                  </label>
                  <span className="d-block fs-5 text-muted">status</span>
                </div>
              </Col>
              <Col xs={12} sm={6} md={3} lg={3}>
                <div className="workorder-field">
                  <label className="fs-4">{data?.TypeCategory}</label>
                  <span className="d-block fs-5 text-muted">type</span>
                </div>
              </Col>
              <Col xs={12} sm={6} md={3} lg={3}>
                <div className="workorder-field">
                  <label className="fs-4">{data?.Customer?.Name}</label>
                  <span className="d-block fs-5 text-muted">client</span>
                </div>
              </Col>

              <Col xs={12} sm={6} md={3} lg={3}>
                <div className="workorder-field">
                  <label className="fs-4">{data?.PoNumber ? data?.PoNumber : "--"}</label>
                  <span className="d-block fs-5 text-muted">P.O.Number</span>
                </div>
              </Col>
              <Col xs={12} sm={6} md={3} lg={3}>
                <div className="workorder-field">
                  <label className="fs-4">
                    <span className="fw-semibold  d-block fs-3">
                      {spaces?.length > 1 && spaces[0]}
                    </span>
                    <span className="fs-2">
                      {spaces?.length > 1 && spaces[1]}
                    </span>
                  </label>
                  <span className="d-block fs-5 text-muted">space</span>
                </div>
              </Col>
              <Col xs={12} sm={6} md={3} lg={3}>
                <div className="workorder-field">
                  <label className="fs-4">--</label>
                  <span className="d-block fs-5 text-muted">address</span>
                </div>
              </Col>
              <Col xs={12} sm={6} md={3} lg={3}>
                <div className="workorder-field">
                  <label className="fs-4">--</label>
                  <span className="d-block fs-5 text-muted">
                    property/workflow
                  </span>
                </div>
              </Col>
              <Col xs={12} sm={6} md={3} lg={3}>
                <div className="workorder-field">
                  <label className="fs-4">
                    <span className="fs-2">
                      {data?.ScheduledStartUtc
                        ? start.tz("UTC").format("MM/DD/YYYY")
                        : ""}{" "}
                      <br />
                      {data?.ScheduledStartUtc
                        ? start.tz("UTC").format("hh:mm A")
                        : "-"}
                    </span>
                  </label>
                  <span className="d-block fs-5 text-muted">scheduled to</span>
                </div>
              </Col>



              <Col xs={12} sm={6} md={3} lg={3}>
                <div className="workorder-field">
                  <label className="fs-4">{data?.Contact?.fullName ? data?.Contact?.fullName : data?.ContactName}</label>
                  <span className="d-block fs-5 text-muted">contact</span>
                </div>
              </Col>
              <Col xs={12} sm={6} md={3} lg={3}>
                <div className="workorder-field">
                  <label className="fs-4">{data?.Contact?.emailAddress ? data?.Contact?.emailAddress : ''}</label>
                  <span className="d-block fs-5 text-muted">Email Address</span>
                </div>
              </Col>
              <Col xs={12} sm={6} md={3} lg={3}>
                <div className="workorder-field">
                  <label className="fs-4">{data?.Contact?.phoneNumber ? data?.Contact?.phoneNumber : ''}</label>
                  <span className="d-block fs-5 text-muted">Phone Number</span>
                </div>
              </Col>
            </Row>
            <Row>
              <h2 className="fs-4 mb-5 mt-5">WORK DESCRIPTION</h2>
              <Table bordered responsive className="workorder-table">
                <thead>
                  <tr>
                    <th>ASSET</th>
                    <th>TASK</th>
                    <th></th>
                    <th>DESCRIPTION</th>
                    <th>ACTIONS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{data?.TaskRefinement?.split(":")[0]}</td>
                    <td>{data?.TaskRefinement?.split(":")[1]}</td>
                    <td>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: taskRefinementHTML(data?.TaskRefinement),
                        }}
                        style={{ lineHeight: "2rem" }}
                        className="fs-4 "
                      />
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </Table>
            </Row>
            <Row>
              <h2 className="fs-4 mb-5 mt-5">WORK PLAN DETAILS</h2>
              <Table bordered responsive className="workorder-table">
                <thead>
                  <tr>
                    <th>FLAG</th>
                    <th>WORK PLAN DETAILS</th>
                    <th>CATEGORY</th>
                    <th>ASSET</th>
                    <th>STEPS</th>
                    <th>STATUS</th>
                    <th>ATTACHEMENTS</th>
                    <th>ASSOCIATED WOS</th>
                    <th>ACTIONS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </Table>
            </Row>
            <Row>
              <h2 className="fs-4 mb-5 mt-5">SCHEDULING AND ASSIGNMENT</h2>
              <Col xs={12} sm={6} md={3} lg={3}>
                <div className="workorder-field">
                  <label className="fs-4">{data?.Priority?.DisplayAs}</label>
                  <span className="d-block fs-5 text-muted">priority</span>
                </div>
              </Col>
              <Col xs={12} sm={6} md={3} lg={3}>
                <div className="workorder-field">
                  <label className="fs-4">
                    NA{" "}
                    {data?.DtOnSiteBy
                      ? onSiteByDate.tz("GMT").format("MM/DD/YYYY")
                      : ""}{" "}
                    {data?.DtOnSiteBy
                      ? onSiteByDate.tz("GMT").format("hh:mm A")
                      : ""}
                  </label>
                  <span className="d-block fs-5 text-muted">
                    access/appt/start
                  </span>
                </div>
              </Col>
              <Col xs={12} sm={6} md={3} lg={3}>
                <div className="workorder-field">
                  <label className="fs-4" style={{ color: "red" }}>
                    {/* {`${data?.DtScheduledStart?.tz("MST").format("MM/DD/YYYY")}`} */}
                    {data?.DtOnSiteBy
                      ? onSiteByDate.tz("GMT").format("MM/DD/YYYY")
                      : "--"}{" "}
                    {data?.DtOnSiteBy
                      ? onSiteByDate.tz("GMT").format("hh:mm A")
                      : "--"}
                  </label>
                  <span className="d-block fs-5 text-muted">on-site by</span>
                </div>
              </Col>
              <Col xs={12} sm={6} md={3} lg={3}>
                <div className="workorder-field">
                  <label className="fs-4">{data?.Employee?.DisplayAs}</label>
                  <span className="d-block fs-5 text-muted">Assigned to</span>
                </div>
              </Col>
              <Col xs={12} sm={6} md={3} lg={3}>
                <div className="workorder-field">
                  <label className="fs-4">
                    {data?.TaskRefinement?.split(":")[1]}
                  </label>
                  <span className="d-block fs-5 text-muted">Specialty</span>
                </div>
              </Col>
              <Col xs={12} sm={6} md={3} lg={3}>
                <div className="workorder-field">
                  <label className="fs-4">
                    {data?.Duration ? data?.Duration / 60 : ""}
                  </label>
                  <span className="d-block fs-5 text-muted">Duration</span>
                </div>
              </Col>
              <Col xs={12} sm={6} md={3} lg={3}>
                <div className="workorder-field">
                  <label className="fs-4" style={{ color: "red" }}>
                    {data?.DtDue
                      ? dueByDate.tz("GMT").format("MM/DD/YYYY")
                      : "--"}{" "}
                    {data?.DtOnSiteBy
                      ? dueByDate.tz("GMT").format("hh:mm A")
                      : "--"}
                  </label>
                  <span className="d-block fs-5 text-muted">Due by</span>
                </div>
              </Col>
              <Col xs={12} sm={6} md={3} lg={3}>
                <div className="workorder-field">
                  <label className="fs-4" style={{ color: "red" }}>
                    {data?.DtAcknowledgeBy
                      ? acknowledgeByDate.tz("GMT").format("MM/DD/YYYY")
                      : "--"}{" "}
                    {data?.DtOnSiteBy
                      ? acknowledgeByDate.tz("GMT").format("hh:mm A")
                      : "--"}
                  </label>
                  <span className="d-block fs-5 text-muted">Acknowledge By</span>
                </div>
              </Col>
            </Row>
            <Row>
              <h2 className="fs-4 mb-5 mt-5">ACTIVITY LOG</h2>
              <Table bordered responsive className="workorder-table">
                <thead>
                  <tr>
                    <th>BY</th>
                    <th>DATE</th>
                    <th>ACTION</th>
                    <th>COMMENTS</th>
                    <th>VIEW</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.ActionLogRecords?.length > 0 ? (
                    data.ActionLogRecords.map((log, index) => (
                      <tr
                        key={index}
                        style={{ borderBottom: "0.5pt solid lightgray" }}
                      >
                        <td>{log?.Actor?.DisplayAs}</td>
                        <td>
                          {moment
                            .tz(log?.ActionDate, "UTC")
                            .tz("GMT")
                            .format("MM/DD/YYYY")}{" "}
                          {moment
                            .tz(log?.ActionDate, "UTC")
                            .tz("GMT")
                            .format("hh:mm A")}
                        </td>

                        <td>{log?.TypeId}</td>
                        <td>{log?.Comment}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>No Records Found</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Row>
            <Row>
              <h2 className="fs-4 mb-5 mt-5">NOTES</h2>
              <Table bordered responsive className="workorder-table">
                <thead>
                  <tr>
                    <th>DATE</th>
                    <th>BY</th>
                    <th>NOTE</th>
                    <th>ACTIONS</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.Notes?.length > 0 ? (
                    data.Notes.map((note, index) => (
                      <tr
                        key={index}
                        style={{ borderBottom: "0.5pt solid lightgray" }}
                      >
                        <td>
                          {moment
                            .tz(note?.CreatedDate, "UTC")
                            .tz("GMT")
                            .format("MM/DD/YYYY")}
                        </td>
                        <td>{note?.CreatedBy?.DisplayAs}</td>
                        <td>{note?.Body}</td>
                        <td></td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>No Records Found</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Row>
            <Row>
              <h2 className="fs-4 mb-5 mt-5">ALERT AND NOTIFICATIONS</h2>
              <Table bordered responsive className="workorder-table">
                <thead>
                  <tr>
                    <th>NAME</th>
                    <th>SENT</th>
                    <th>SENT TO</th>
                    <th>ADDRESS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </Table>
            </Row>
            <Row>
              <h2 className="fs-4 mb-5 mt-5">EQUIPMENT WORKED ON</h2>
              <Table bordered responsive className="workorder-table">
                <thead>
                  <tr>
                    <th>EQUIPMENT WORKED ON</th>
                    <th>COMMENT</th>
                    <th>ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.EquipmentWorkedOn?.length > 0 ? (
                    data.EquipmentWorkedOn.map((item, index) => (
                      <tr
                        key={index}
                        style={{ borderBottom: "0.5pt solid lightgray" }}
                      >
                        <td>{item?.Type}</td>
                        <td>{item?.Comment}</td>
                        <td></td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>No Records Found</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Row>
            <Row>
              <Col>
                <Row>
                  <h2 className="fs-4 mb-5 mt-5">SECONDARY ASSIGNEES</h2>
                  <Table bordered responsive className="workorder-table">
                    <thead>
                      <tr>
                        <th>NAME</th>
                        <th>CHECKED IN</th>
                        <th>ACTIONS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.Assignments?.length > 1 ? (
                        <tr>
                          <td>
                            {
                              listOfEmployees?.find(
                                (employee) =>
                                  employee.Data.Id ===
                                  data?.Assignments[1]?.EmployeeId
                              )?.Data?.DisplayAs
                            }
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td>No Records Found</td>
                        </tr>
                      )}
                      <tr>
                        <td></td>
                      </tr>
                    </tbody>
                  </Table>
                </Row>
                <Row>
                  <h2 className="fs-4 mb-5 mt-5">TO-DO</h2>
                  <Table bordered responsive className="workorder-table">
                    <thead>
                      <tr>
                        <th>DONE</th>
                        <th>DESCRIPTION</th>
                        <th>DUE BY (USER TIME)</th>
                        <th>ACTIONS</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </Table>
                </Row>
                <Row>
                  <h2 className="fs-4 mb-5 mt-5">DOCUMENTS</h2>
                  <Table bordered responsive className="workorder-table">
                    <thead>
                      <tr>
                        <th>TITLE</th>
                        <th>TYPE</th>
                        <th>SHARE</th>
                        <th>CREATED ON</th>
                        <th>END DATE</th>
                        <th>
                          <div>
                            <input type="file" accept=".gif,.jpg,.jpeg,.png,.doc,.pdf,.docx" id="fileUpload" hidden onChange={fileUploadHandler} />
                            <Button style={{ padding: "5px", fontSize: "14px" }} as="label" htmlFor="fileUpload" >{uploadBtnText}</Button>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.Documents?.length > 0 ? (
                        data.Documents.map((doc, index) => (
                          <tr
                            key={index}
                            style={{ borderBottom: "0.5pt solid lightgray" }}
                          >
                            <td>
                              <a
                                target="_blank"
                                href={doc?.DocUrl}
                                rel="noreferrer"
                              >
                                {doc?.Title}
                              </a>
                            </td>
                            <td>{doc?.DocType?.DisplayAs}</td>
                            <td>{doc?.IsShared ? "Yes" : "No"}</td>
                            <td>
                              {moment
                                .tz(doc?.StartDate, "UTC")
                                .tz("GMT")
                                .format("MM/DD/YYYY")}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td>No Records Found</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Row>
                <Row>
                  <h2 className="fs-4 mb-5 mt-5">CHECK IN/OUT LOG</h2>
                  <Table bordered responsive className="workorder-table">
                    <thead>
                      <tr>
                        <th>BY</th>
                        <th>CHECK IN</th>
                        <th>CHECK OUT</th>
                        <th>ACTIONS</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </Table>
                </Row>
                <Row>
                  <Col xs={6} md={6} lg={6}>
                    <div className="text-end pt-15">
                      <button
                        type="button"
                        className="btn btn-danger"
                        style={{
                          width: "100%",
                        }}
                        onClick={() => showDeleteModalHandler(data?._id)}
                      >
                        Delete
                      </button>
                    </div>
                  </Col>
                  <Col xs={6} md={6} lg={6}>
                    <div className="text-end pt-15">
                      <button
                        type="button"
                        className="btn btn-primary"
                        style={{
                          width: "100%",
                        }}
                        onClick={() =>
                          navigate(`/hf-order/update/${data?._id}`)
                        }
                      >
                        Edit
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};

export default WorkOrderView;
