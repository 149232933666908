import moment from "moment-timezone";
import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { taskRefinementHTML } from "../../../../utils/helpers";

const WorkOrderDetail = ({ workOrderData }) => {
  useEffect(() => {}, [workOrderData.Data.Id]);
  let data = workOrderData?.Data;

  const start = moment.tz(data.ScheduledStartUtc, "UTC"); // original timezone
  let spaces = data.ShortLocation.split("\\");

  return (
    <>
      <div className="border-0 d-flex align-items-center p-5 mb-5 card card-xl-stretch mb-xl-8">
        <div className="fv-row mb-7">
          <Row
            style={{
              marginTop: "20px",
              marginBottom: "50px",
            }}
          >
            <h2>Work Order Detail</h2>
          </Row>
          <Row>
            <Col>
              <div className="fv-row mb-7">
                <label className="fw-bold mb-2 text-muted fs-4">
                  Work Order Number
                </label>
                <p className="fs-2">
                  {" "}
                  <a
                    target={"_blank"}
                    className="menu-link px-3"
                    href={`https://am-ce99a.corrigo.com/corpnet/workorder/workorderdetails.aspx/${data?.Id}`}
                    rel="noreferrer"
                  >
                    {data?.Number}
                  </a>
                </p>
              </div>

              <div className="fv-row mb-7">
                <label className="fw-bold mb-2 text-muted fs-4">
                  Purchase Order Number
                </label>
                <p className="fs-2">{data?.PoNumber}</p>
              </div>
            </Col>

            <Col>
              <div className="fv-row mb-7">
                <label className="fw-bold mb-2 text-muted fs-4">Status</label>
                <p>
                  {data?.StatusId == "New" ? (
                    <i className="bi bi-file-plus-fill fs-0" title="New"></i>
                  ) : (
                    <i
                      className="bi bi-file-check-fill fs-0"
                      title="Completed"
                    ></i>
                  )}
                  &nbsp;<span className="fs-2">{data?.StatusId}</span>
                </p>
              </div>

              <div className="fv-row mb-7">
                <label className="fw-bold mb-2 text-muted fs-4">Priority</label>
                <p className="fs-2 ">{data?.Priority?.DisplayAs}</p>
              </div>
            </Col>

            <div className="fv-row mb-7">
              <label className="fw-bold fs-4 text-muted mb-2">SPACE</label>
              <span className="fw-semibold  d-block fs-3">{spaces[0]}</span>
              <span className="fs-2">{spaces[1]}</span>
            </div>

            <div className="fv-row mb-7">
              <label className="fw-bold mb-2 text-muted fs-4">
                Description
              </label>
              {/* <p>{formik.values.workOrderNumber ? formik.values.workOrderNumber : ""}</p> */}
              <span
                dangerouslySetInnerHTML={{
                  __html: taskRefinementHTML(data?.TaskRefinement),
                }}
                style={{ lineHeight: "3rem" }}
                className="fs-2 bagde-xl-child"
              />
            </div>

            <div className="fv-row mb-7">
              <label className="fw-bold mb-2 text-muted fs-4">Document</label>
              {data?.Documents?.length > 0 ? (
                data.Documents.map((doc, index) => (
                  <p key={index} className="fs-2">
                    <a target="_blank" href={doc?.DocUrl} rel="noreferrer">
                      {doc?.Title}
                    </a>
                  </p>
                ))
              ) : (
                <p className="fs-2">No Document found</p>
              )}
            </div>

            <div className="fv-row mb-7">
              <label className="fw-bold mb-2 text-muted fs-4">
                Assigned To
              </label>
              <p className="fs-2">{data?.Employee?.DisplayAs}</p>
            </div>

            <div className="fv-row mb-7">
              <label className="fw-bold mb-2 text-muted fs-4">
                Schedule To
              </label>{" "}
              <br />
              <span className="fs-2">
                {data.ScheduledStartUtc
                  ? start.tz("MST").format("MM/DD/YYYY")
                  : ""}{" "}
                <br />
                {data.ScheduledStartUtc
                  ? start.tz("MST").format("hh:mm A")
                  : "-"}
              </span>
            </div>

            <div className="fv-row mb-7">
              <label className="fw-bold mb-2 text-muted fs-4">Flag</label>
              <p className="fs-2">
                {data?.Flag?.DisplayAs.length > 0
                  ? `${data.Flag.DisplayAs}`
                  : " No Flag Found"}
              </p>
            </div>
            <div className="fv-row mb-7">
              <label className="fw-bold mb-2 text-muted fs-4">
                Contact Name
              </label>
              <p className="fs-2">{data?.ContactName}</p>
            </div>
          </Row>
        </div>
      </div>
    </>
  );
};

export default WorkOrderDetail;
